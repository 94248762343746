// @ts-nocheck
import "./polyfills";
// eslint-disable-next-line import/extensions
import "hoxton-iview-theme";
import Vue from "vue";
import iView from "iview";
import iViewLocale from "iview/dist/locale/en-US";
import VueApollo from "vue-apollo";
import PortalVue from "portal-vue";
import Vuebar from "vuebar";
import { sync } from "vuex-router-sync";
import App from "@/App";
import "@/components/common";
import router from "@/router";
import store from "@/store";
import filters from "@/filters";
import * as auth from "@/services/Auth";
import { getDomain, snackbar } from "@/utils";
import { apolloProvider } from "@/apollo";
import { UiAction } from "@/store/modules/ui";
import { BaseUrl } from "@/components/Reporting/data/constants";
store.dispatch(UiAction.SetDomain, getDomain());
console.log(`STACK SETTINGS:
domain: ${getDomain()},
backend: ${BaseUrl},
document.domain: ${document.domain},
reportingDebug: ${!!localStorage.getItem("reportingDebug")}
`);
const helpers = {
    install() {
        Vue.prototype.$auth = auth;
        Vue.prototype.$snackbar = snackbar;
    }
};
sync(store, router);
Vue.use(iView, { locale: iViewLocale });
Vue.use(VueApollo);
Vue.use(PortalVue);
Vue.use(Vuebar);
Vue.use(helpers);
Vue.use(filters);
Vue.config.productionTip = false;
// Vue.config.performance = true;
/* eslint-disable no-new */
new Vue({
    el: "#app",
    router,
    store,
    apolloProvider,
    components: { App },
    template: "<App/>"
});

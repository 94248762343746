import { Breakdown, CreativeInsightsContentFilter, CreativeInsightsMode } from "@/enums/creativeInteligence";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import { getMetricStat } from "@/utils/creativeInteligence";

const storeNamespace = "creativeInsights";

export const CreativeInsightsAction = {
    Init: `${storeNamespace}/init`,
    InitSelectedDynamicBreakdowns: `${storeNamespace}/initSelectedDynamicBreakdowns`,
    OpenDrawer: `${storeNamespace}/OpenDrawer`,
    SaveSettings: `${storeNamespace}/saveSettings`,
    SetAdvancedSearch: `${storeNamespace}/setAdvancedSearch`,
    SetBreakdowns: `${storeNamespace}/setBreakdowns`,
    SetContentFilter: `${storeNamespace}/setContentFilter`,
    SetDisplayMode: `${storeNamespace}/setDisplayMode`,
    SetDrawerCreatives: `${storeNamespace}/setDrawerCreatives`,
    SetDynamicBreakdowns: `${storeNamespace}/setDynamicBreakdowns`,
    SetIndexedPercentage: `${storeNamespace}/setIndexedPercentage`,
    SetIsIndexed: `${storeNamespace}/setIsIndexed`,
    SetIndexingError: `${storeNamespace}/setIndexingError`,
    SetMetadata: `${storeNamespace}/setMetadata`,
    SetReportMetricNames: `${storeNamespace}/setReportMetricNames`,
    SetReportMetricStats: `${storeNamespace}/setReportMetricStats`,
    SetSelectedBreakdown: `${storeNamespace}/setSelectedBreakdown`,
    SetSelectedDynamicBreakdowns: `${storeNamespace}/setSelectedDynamicBreakdowns`,
    SetSettings: `${storeNamespace}/setSettings`,
    SetShowHeatmap: `${storeNamespace}/setShowHeatmap`,
    SetSortBy: `${storeNamespace}/setSortBy`,
    SetWorkerId: `${storeNamespace}/setWorkerId`,
    ToggleSettingsModal: `${storeNamespace}/toggleSettingsModal`
};

export const CreativeInsightsMutation = {
    FetchSettings: "fetchSettings",
    Reset: "reset",
    SaveSettings: "saveSettings",
    SetAdvancedSearch: "setAdvancedSearch",
    SetBreakdowns: "setBreakdowns",
    SetCampaignId: "setCampaignId",
    SetContentFilter: "setContentFilter",
    SetDisplayMode: "setDisplayMode",
    SetDrawerCreatives: "setDrawerCreatives",
    SetDynamicBreakdowns: "setDynamicBreakdowns",
    SetIndexedPercentage: "setIndexedPercentage",
    SetIsIndexed: "setIsIndexed",
    SetIndexingError: "setIndexingError",
    SetMetadata: "setMetadata",
    SetReportMetricNames: "setReportMetricNames",
    SetReportMetricStats: "setReportMetricStats",
    SetSelectedBreakdown: "setSelectedBreakdown",
    SetSelectedDynamicBreakdowns: "setSelectedDynamicBreakdowns",
    SetShowHeatmap: "setShowHeatmap",
    SetSortBy: "setSortBy",
    SetWorkerId: "setWorkerId",
    ToggleIsSettingsModalOpen: "toggleIsSettingsModalOpen"
};

export const CreativeInsightsGetters = {
    AdvancedSearchValue: `${storeNamespace}/advancedSearchValue`,
    ApiDiscardThresholds: `${storeNamespace}/apiDiscardThresholds`,
    ConfidenceMetricMode: `${storeNamespace}/confidenceMetricMode`,
    ConfidenceMetricName: `${storeNamespace}/confidenceMetricName`,
    ConfidenceMetricStat: `${storeNamespace}/confidenceMetricStat`,
    ConfidenceMetricValue: `${storeNamespace}/confidenceMetricValue`,
    DynamicBreakdownValuesByBreakdown: `${storeNamespace}/dynamicBreakdownValuesByBreakdown`,
    DynamicBreakdownsFilter: `${storeNamespace}/dynamicBreakdownsFilter`,
    HasHeatmaps: `${storeNamespace}/hasHeatmaps`,
    IsNoBreakdownsAvailable: `${storeNamespace}/isNoBreakdownsAvailable`,
    KpiMetricMode: `${storeNamespace}/kpiMetricMode`,
    KpiMetricName: `${storeNamespace}/kpiMetricName`,
    KpiMetricStat: `${storeNamespace}/kpiMetricStat`,
    KpiMetricValue: `${storeNamespace}/kpiMetricValue`,
    QuadrantValue: `${storeNamespace}/quadrantValue`
};

export default {
    namespaced: true,

    state: {
        advancedSearch: "",
        breakdowns: [],
        confidenceMetric: { metric: undefined, value: undefined, mode: undefined },
        contentFilter: CreativeInsightsContentFilter.Everything,
        campaignId: null,
        discardThresholds: {},
        displayMode: CreativeInsightsMode.InsightsReport,
        drawerCreatives: [],
        drawerOptions: {
            endpoint: InsightType.KeyTakeaway,
            requestOptions: {}
        },
        dynamicBreakdowns: [],
        indexedPercentage: 0,
        isIndexed: true,
        indexingError: "",
        isSettingsModalOpen: false,
        kpiMetric: { metric: undefined, value: undefined, mode: undefined },
        metadata: {},
        reportMetricNames: [],
        reportMetricStats: {},
        selectedBreakdown: Breakdown.NoBreakdowns,
        selectedDynamicBreakdowns: {},
        settings: {}, // todo: check
        showHeatmap: false,
        sortBy: { field: undefined, order: "desc" },
        workerId: ""
    },

    getters: {
        advancedSearchValue: (state, getters, rootState) => {
            if (rootState.ciTags.selectedTagIds.length) {
                return rootState.ciTags.tagsById[rootState.ciTags.selectedTagIds[0]].advancedSearch;
            }

            return state.advancedSearch;
        },

        apiDiscardThresholds: state => {
            return Object.entries(state.discardThresholds).reduce((acc, [metric, value]) => {
                if (Array.isArray(value)) {
                    value.forEach(v => acc.push({ metric, value: v }));
                } else {
                    acc.push({ metric, value });
                }

                return acc;
            }, []);
        },

        dynamicBreakdownsFilter: state => {
            return Object.keys(state.selectedDynamicBreakdowns).reduce((acc, groupName) => {
                // in the situation when all the filters from a single group are selected we don't
                // include that group as the results will be the same as if we did not pass this group at all
                // UPDATE since in some datasets the above sentence is not true we will be passing a full list of params.
                if (state.selectedDynamicBreakdowns[groupName].length !== 0) {
                    acc[groupName] = state.selectedDynamicBreakdowns[groupName];
                }

                return acc;
            }, {});
        },

        dynamicBreakdownValuesByBreakdown: state => {
            const withSets = state.breakdowns.reduce((acc, name) => {
                const breakdownWithValues = name.split("__$$__");
                breakdownWithValues.forEach(breakdownWithValue => {
                    const [breakdown, breakdownValue] = breakdownWithValue.split("#$#");

                    if (!acc[breakdown]) {
                        acc[breakdown] = new Set();
                    }

                    if (breakdownValue !== undefined) {
                        acc[breakdown].add(breakdownValue);
                    }
                });

                return acc;
            }, {});

            return Object.keys(withSets).reduce((acc, breakdown) => {
                acc[breakdown] = [...withSets[breakdown]].sort();

                return acc;
            }, {});
        },

        confidenceMetricMode: state => {
            if (!state.confidenceMetric) {
                return "avg";
            }

            return state.confidenceMetric.mode;
        },

        confidenceMetricName: state => {
            if (!state.confidenceMetric) {
                return "";
            }

            return state.confidenceMetric.metric;
        },

        confidenceMetricStat: state => {
            return getMetricStat(state.confidenceMetric.metric);
        },

        confidenceMetricValue: state => {
            if (!state.confidenceMetric) {
                return "";
            }

            return state.confidenceMetric.value;
        },

        hasHeatmaps: state => {
            return state.metadata && state.metadata.dragonfly;
        },

        isNoBreakdownsAvailable: state => {
            return state.breakdowns.findIndex(({ name }) => name === Breakdown.NoBreakdowns) !== -1;
        },

        kpiMetricMode: state => {
            if (!state.kpiMetric) {
                return "avg";
            }

            return state.kpiMetric.mode;
        },

        kpiMetricName: state => {
            if (!state.kpiMetric) {
                return "";
            }

            return state.kpiMetric.metric;
        },

        kpiMetricStat: state => {
            return getMetricStat(state.kpiMetric.metric);
        },

        kpiMetricValue: state => {
            if (!state.kpiMetric) {
                return "";
            }

            return state.kpiMetric.value;
        },

        quadrantValue: state => (kpiValue, confidenceValue) => {
            const confidenceHalf = confidenceValue > state.confidenceMetric.value ? 1 : 0;
            const kpiHalf = kpiValue > state.kpiMetric.value ? 1 : 0;

            return `${kpiHalf}${confidenceHalf}`;
        }
    },

    mutations: {
        fetchSettings(state, scopeId) {
            const storageSettingsString = localStorage.getItem("creativeIntelligenceSettings");

            state.reportMetricNames = [];
            state.reportMetricStats = {};

            if (storageSettingsString) {
                const settings = JSON.parse(storageSettingsString);

                state.kpiMetric = { metric: undefined, value: undefined };
                state.confidenceMetric = { metric: undefined, value: undefined };
                state.discardThresholds = {};

                if (settings[scopeId]) {
                    const { kpiMetric, confidenceMetric, discardThresholds } = settings[scopeId];

                    if (kpiMetric) {
                        state.kpiMetric = kpiMetric;
                        state.sortBy = { field: state.kpiMetric.metric, order: "desc" };
                    }

                    if (confidenceMetric) {
                        state.confidenceMetric = confidenceMetric;
                    }

                    state.discardThresholds = discardThresholds || {};
                }
            }
        },

        reset(state) {
            state.breakdowns = [];
            state.drawerCreatives = [];
            state.dynamicBreakdowns = [];
            state.metadata = {};
            state.contentFilter = CreativeInsightsContentFilter.Everything;
            state.selectedBreakdown = Breakdown.NoBreakdowns;
            state.showHeatmap = false;
            state.isIndexed = true;
            state.indexingError = "";
            state.indexedPercentage = 0;
        },

        saveSettings(state, settings) {
            const kpiMetric = {
                metric: settings.kpiMetricsName,
                value: settings.kpiMetricsValue,
                mode: settings.kpiMetricsMode
            };
            const confidenceMetric = {
                metric: settings.confidenceMetricsName,
                value: settings.confidenceMetricsValue,
                mode: settings.confidenceMetricsMode
            };
            const { discardThresholds, scopeId } = settings;

            const storageSettingsString = localStorage.getItem("creativeIntelligenceSettings");
            const savedSettings = JSON.parse(storageSettingsString);

            const toSave = {
                ...savedSettings,
                [scopeId]: { kpiMetric, confidenceMetric, discardThresholds }
            };

            localStorage.setItem("creativeIntelligenceSettings", JSON.stringify(toSave));
        },

        setAdvancedSearch(state, advancedSearch) {
            state.advancedSearch = advancedSearch;
        },

        setBreakdowns(state, breakdowns) {
            state.breakdowns = breakdowns;
        },

        setCampaignId(state, campaignId) {
            state.campaignId = campaignId;
        },

        setContentFilter(state, contentFilter) {
            state.contentFilter = contentFilter;
        },

        setDisplayMode(state, displayMode) {
            state.displayMode = displayMode;
        },

        setDrawerCreatives(state, drawerCreatives) {
            state.drawerCreatives = drawerCreatives;
        },

        setDynamicBreakdowns(state, payload) {
            state.dynamicBreakdowns = payload;
        },

        setIndexedPercentage(state, payload) {
            state.indexedPercentage = payload;
        },

        setIsIndexed(state, payload) {
            state.isIndexed = payload;
        },

        setIndexingError(state, payload) {
            state.indexingError = payload;
        },

        setMetadata(state, payload) {
            state.metadata = payload;
        },

        setReportMetricNames(state, metricsNames) {
            state.reportMetricNames = metricsNames;
        },

        setReportMetricStats(state, metricsStats) {
            state.reportMetricStats = metricsStats;
        },

        setSelectedBreakdown(state, selectedBreakdown) {
            state.selectedBreakdown = selectedBreakdown;
        },

        setSelectedDynamicBreakdowns(state, payload) {
            state.selectedDynamicBreakdowns = payload;
        },

        setShowHeatmap(state, payload) {
            state.showHeatmap = payload;
        },

        setSortBy(state, payload) {
            state.sortBy = payload;
        },

        setWorkerId(state, workerId) {
            state.workerId = workerId;
        },

        toggleIsSettingsModalOpen(state) {
            state.isSettingsModalOpen = !state.isSettingsModalOpen;
        }
    },

    actions: {
        init({ commit, state, rootState }) {
            commit(CreativeInsightsMutation.Reset);
            commit(CreativeInsightsMutation.SetWorkerId, "");
            commit(CreativeInsightsMutation.FetchSettings, rootState.route.params.campaignId);
            if (rootState.route.params.campaignId !== state.campaignId) {
                // reset selected filters if there was a campaign change
                commit(CreativeInsightsMutation.SetSelectedDynamicBreakdowns, {});
                commit(CreativeInsightsMutation.SetAdvancedSearch, "");

                console.log("reset selected filters");
            }
            commit(CreativeInsightsMutation.SetCampaignId, rootState.route.params.campaignId);
        },

        initSelectedDynamicBreakdowns({ commit, getters }) {
            commit(CreativeInsightsMutation.SetSelectedDynamicBreakdowns, {
                ...getters.dynamicBreakdownValuesByBreakdown,
                "date.start": [],
                "date.end": []
            });
        },

        saveSettings({ commit, rootState }, settings) {
            commit(CreativeInsightsMutation.SaveSettings, settings);
            commit(CreativeInsightsMutation.FetchSettings, rootState.route.params.campaignId);
        },

        setAdvancedSearch({ commit }, advancedSearch) {
            commit(CreativeInsightsMutation.SetAdvancedSearch, advancedSearch);
        },

        setBreakdowns({ commit }, data) {
            commit(CreativeInsightsMutation.SetBreakdowns, data);
        },

        setContentFilter({ commit }, contentFilter) {
            commit(CreativeInsightsMutation.SetContentFilter, contentFilter);
        },

        setDisplayMode({ commit }, displayMode) {
            commit(CreativeInsightsMutation.SetDisplayMode, displayMode);
        },

        setDrawerCreatives({ commit }, drawerCreatives) {
            commit(CreativeInsightsMutation.SetDrawerCreatives, drawerCreatives);
        },

        setDynamicBreakdowns({ commit }, data) {
            commit(CreativeInsightsMutation.SetDynamicBreakdowns, data);
        },

        setIndexedPercentage({ commit }, data) {
            commit(CreativeInsightsMutation.SetIndexedPercentage, data);
        },

        setIsIndexed({ commit }, data) {
            commit(CreativeInsightsMutation.SetIsIndexed, data);
        },

        setIndexingError({ commit }, data) {
            commit(CreativeInsightsMutation.SetIndexingError, data);
        },

        setMetadata({ commit }, data) {
            commit(CreativeInsightsMutation.SetMetadata, data);
        },
        setReportMetricNames({ commit }, metricNames) {
            commit(CreativeInsightsMutation.SetReportMetricNames, metricNames);
        },

        setReportMetricStats({ commit }, metricStats) {
            commit(CreativeInsightsMutation.SetReportMetricStats, metricStats);
        },

        setSelectedBreakdown({ commit }, data) {
            commit(CreativeInsightsMutation.SetSelectedBreakdown, data);
        },

        setSelectedDynamicBreakdowns({ commit }, data) {
            commit(CreativeInsightsMutation.SetSelectedDynamicBreakdowns, data);
        },

        setShowHeatmap({ commit }, data) {
            commit(CreativeInsightsMutation.SetShowHeatmap, data);
        },

        setSortBy({ commit }, data) {
            commit(CreativeInsightsMutation.SetSortBy, data);
        },

        setWorkerId({ commit }, workerId) {
            commit(CreativeInsightsMutation.SetWorkerId, workerId);
        },

        toggleSettingsModal({ commit }) {
            commit(CreativeInsightsMutation.ToggleIsSettingsModalOpen);
        }
    }
};

const EditableEvent = {
    Update: "update",
    Remove: "remove",
    Restore: "restore"
};

const EditableGroupEvent = {
    Add: "add",
    Update: "update",
    Edit: "edit",
    Remove: "remove",
    Batch: "batch",
    Duplicate: "duplicate"
};

const EditableType = {
    Array: "array",
    BackgroundImage: "backgroundImage",
    Boolean: "boolean",
    Css: "css",
    File: "file",
    Folder: "folder",
    Image: "image",
    Textarea: "textarea",
    Subtitles: "subtitles",
    Text: "text",
    Url: "url",
    Video: "video"
};

const EditableTypeWidgetOverrides = {
    backgroundimage: "image",
    subtitles: "file",
    [EditableType.Textarea]: EditableType.Text
};
const EditableImageTypes = [EditableType.Image, EditableType.BackgroundImage];
const EditableFileTypes = [EditableType.File, EditableType.Video, ...EditableImageTypes];
const EditableMediaTypes = [EditableType.Folder, ...EditableFileTypes];

const EditableScope = {
    Standalone: "standalone",
    Group: "group"
};

const ExportableEditableTypes = [
    EditableType.Text,
    EditableType.Textarea,
    EditableType.Subtitles,
    ...EditableFileTypes,
    EditableType.Array,
    EditableType.Boolean,
    EditableType.Css
];
const ExportableTranslationTypes = [EditableType.Text, EditableType.Textarea, EditableType.Subtitles];

const EditableSettingProperty = {
    FontColor: "fontColor",
    FontFamily: "fontFamily",
    FontSize: "fontSize",
    FontStyle: "fontStyle",
    FontWeight: "fontWeight",
    Height: "height",
    Left: "left",
    LetterSpacing: "letterSpacing",
    LineHeight: "lineHeight",
    Scale: "scale",
    TranslationMethod: "translationMethod",
    Top: "top",
    Width: "width",
    Font: "font"
};

const EditableTranslateMethod = {
    Transform: "transform",
    Relative: "relative",
    Margin: "margin",
    BackgroundPosition: "background-position"
};

export {
    ExportableEditableTypes,
    ExportableTranslationTypes,
    EditableGroupEvent,
    EditableEvent,
    EditableMediaTypes,
    EditableImageTypes,
    EditableScope,
    EditableSettingProperty,
    EditableTranslateMethod,
    EditableType,
    EditableTypeWidgetOverrides,
    EditableFileTypes
};

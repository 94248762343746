<template>
    <div :class="currentRouteClass">
        <!--
        To prevent issues that occur from a user not being initialized we use a
        loading layer as a guard to ensure that a user does not progress into the
        application while they are authorizing or are authorized but do not yet
        have scopes loaded and you're not on the user not found page as if so me would be null
        -->
        <hox-loading-layer v-if="isAuthorizing || (isAuthorized && !me && !isUserNotFoundRoute)" size="large" />
        <template v-else>
            <notifications-drawer />
            <component :is="currentTheme" />
            <hox-modal v-if="mediaLibraryModalIsVisible" fullscreen @close="hideMediaLibraryModal">
                <template #header>Content Library</template>
                <media-library />
                <template #footer>
                    <portal-target name="media-library-modal-footer" slim />
                </template>
            </hox-modal>
            <hox-modal v-if="adblockModalIsVisible" @close="hideAdblockModal">
                <template #header>Ad Blocker detected!</template>
                <div>
                    You will need to disable your Ad Blocker on *.hoxton.co and *.hogarth.ai to view the banners on this
                    platform.
                </div>
                <template #footer>
                    <div class="remember-checkbox">
                        <Checkbox :value="false" @input="rememberAdblockSetting">Don't show this again</Checkbox>
                    </div>
                    <Button @click="hideAdblockModal">Close</Button>
                </template>
            </hox-modal>
        </template>
        <modal-portal />
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import bus from "@/bus";
import MediaLibrary from "@/components/MediaLibrary";
import HoxModal from "@/components/Modal/Modal/Modal";
import ModalPortal from "@/components/Modal/Modal/ModalPortal";
import NotificationsDrawer from "@/components/Notifications/NotificationsDrawer";
import { MediaLibraryAction } from "@/store/modules/mediaLibrary";
import ThemeDefault from "@/ThemeDefault";
import ThemeLogin from "@/ThemeLogin";
import ThemeCampaignDark from "@/ThemeCampaignDark";
import ThemeCampaignLight from "@/ThemeCampaignLight";
import ThemeTemplatesLight from "@/ThemeTemplatesLight";
import ThemeCreativeInsights from "@/ThemeCreativeInsights";
import meQuery from "@/apollo/queries/Me.gql";
import rolesQuery from "@/apollo/queries/Roles.gql";
import getContentGenerationApiInfoQuery from "@/apollo/queries/v2/GetContentGenerationApiInfo.gql";
import { AuthMutation } from "@/store/modules/auth";
import { RolesAction } from "@/store/modules/roles";
import { UiAction } from "@/store/modules/ui";

import { getIsAuthorized } from "@/services/Auth";
import { getWppToken } from "@/services/wppAuth";

const AdblockStorageKey = "adblockNoticeDisabled";

export default {
    name: "App",
    components: {
        HoxModal,
        MediaLibrary,
        ModalPortal,
        NotificationsDrawer,
        ThemeCampaignDark,
        ThemeCampaignLight,
        ThemeCreativeInsights,
        ThemeDefault,
        ThemeLogin,
        ThemeTemplatesLight
    },

    data() {
        return {
            lock: false,
            isAuthorizing: false,
            localAdblockModalIsVisible: true
        };
    },

    computed: {
        ...mapState("auth", ["isAuthorized", "me", "contentGenerationApiInfo"]),
        ...mapState("ui", ["theme"]),
        ...mapState(["isMaintenanceMode"]),

        currentTheme() {
            return `theme-${this.theme}`;
        },

        isNetworkErrorRoute() {
            return this.route.name === "NetworkError";
        },

        isLocalDevNetworkErrorRoute() {
            return this.route.name === "LocalDevNetworkError";
        },

        isUserNotFoundRoute() {
            return this.route.name === "UserNotFound";
        },

        getCurrentTheme: {
            get() {
                return this.$store.state.ui.themeMode;
            },

            set(val) {
                this.$store.dispatch(UiAction.SetThemeMode, val);
            }
        },

        currentRouteClass() {
            const noTopBar =
                window.self !== window.top && localStorage.getItem("hoxDomain") === "imagine"
                    ? "wpp-theme-notopbar"
                    : "";
            const currentTheme = this.getCurrentTheme.theme || "light";
            return `app-wrapper wpp-theme ${noTopBar} wpp-theme-${currentTheme} wpp-theme--${this.route.name.toLowerCase()}`;
        },

        adblockModalIsVisible() {
            return this.localAdblockModalIsVisible && !window.canRunAds && !localStorage.getItem(AdblockStorageKey);
        },

        mediaLibraryModalIsVisible() {
            return this.$store.state.mediaLibrary.open;
        },

        route() {
            return this.$store.state.route;
        }
    },
    // eslint-disable-next-line complexity
    async created() {
        let route;

        if (this.isInMaintenanceMode()) {
            return;
        }

        this.$snackbar.set(this.$Message);
        this.isAuthorizing = true;

        if (this.$route.query?.type === "wpp") {
            try {
                await getWppToken();

                this.setIsWppLogin(true);
                this.setIsAuthorized(true);

                route = this.$router.getLandingRoute(this.$route);
            } catch (e) {
                console.error("Failed to get WPP token", e);

                try {
                    await this.amplifyAuthorize();
                } catch (err) {
                    this.$snackbar.authentication(err);

                    route = { name: "Login" };
                }
            }
        } else {
            try {
                await this.amplifyAuthorize();
            } catch (err) {
                this.$snackbar.authentication(err);

                route = { name: "Login" };
            }
        }

        if (this.$route.query.error_description) {
            const message = this.$route.query.error_description;
            if (message === "User is not assigned to the client application.; error=access_denied") {
                this.$snackbar.info(
                    'Oops! Looks like you don\'t have access.Click <a href="https://hogarthww.sharepoint.com/sites/IntelligentContentHub">here</a> to request access now',
                    "",
                    {
                        closable: true,
                        duration: 0
                    }
                );
            } else {
                this.$snackbar.info("Oops! Looks like you don't have access.", message, {
                    closable: true,
                    duration: 0
                });
            }
        }

        route = this.$router.getLandingRoute(this.$route);

        this.isAuthorizing = false;

        if (route) {
            if (route.name !== this.$route.name || this.$route.query.code) {
                this.$router.push(route);
            }
        }

        const savedTheme = JSON.parse(localStorage.getItem("currentTheme"));

        if (savedTheme) {
            this.getCurrentTheme = savedTheme;
        }
    },

    mounted() {
        bus.$on("userNotFound", () => {
            if (!this.isUserNotFoundRoute) {
                this.$router.push({ name: "UserNotFound" });
            }
        });

        bus.$on("networkError", () => {
            if (!this.isNetworkErrorRoute) {
                this.$router.push({ name: "NetworkError" });
            }
        });

        bus.$on("localDevNetworkError", () => {
            if (!this.isLocalDevNetworkErrorRoute) {
                this.$router.push({ name: "LocalDevNetworkError" });
            }
        });
    },

    methods: {
        ...mapMutations("auth", ["setIsAuthorized", "setContentGenerationApiInfo", "setIsWppLogin"]),

        isInMaintenanceMode() {
            if (this.isMaintenanceMode && localStorage.getItem("maintainer") !== "true") {
                this.setIsAuthorized(false);
                // Make sure we're on the maintenance page
                if (this.$route.name !== "Maintenance") {
                    this.$router.push({ name: "Maintenance" });
                }

                return true;
            }

            return false;
        },

        async authorize() {
            if (this.$route.query.iss) {
                this.$auth.providerLogin("Hogarth");
            }

            if (this.$route.query.error_description) {
                const message = this.$route.query.error_description;
                if (message === "User is not assigned to the client application.; error=access_denied") {
                    this.$snackbar.info(
                        'Oops! Looks like you don\'t have access.Click <a href="https://hogarthww.sharepoint.com/sites/IntelligentContentHub">here</a> to request access now',
                        "",
                        {
                            closable: true,
                            duration: 0
                        }
                    );
                } else {
                    this.$snackbar.info("Oops! Looks like you don't have access.", message, {
                        closable: true,
                        duration: 0
                    });
                }
            }

            return true;
        },

        async amplifyAuthorize() {
            const authorized = await getIsAuthorized();
            this.setIsAuthorized(authorized);

            if (this.$route.query.iss) {
                await this.$auth.providerLogin("Hogarth");
            }
        },

        hideAdblockModal() {
            this.localAdblockModalIsVisible = false;
        },

        hideMediaLibraryModal() {
            this.$store.dispatch(MediaLibraryAction.Close);
        },

        rememberAdblockSetting(val) {
            if (val) {
                localStorage.setItem(AdblockStorageKey, true);
            } else {
                localStorage.removeItem(AdblockStorageKey);
            }
        }
    },

    apollo: {
        me: {
            query: meQuery,
            pollInterval: 30000,
            skip() {
                return !this.isAuthorized;
            },
            result({ data, error }) {
                if (!error && data.me) {
                    this.$store.commit(AuthMutation.SetMe, { me: data.me });
                }
            }
        },
        roles: {
            query: rolesQuery,
            skip() {
                return (
                    !this.isAuthorized ||
                    !this.$store.state.auth.me ||
                    !this.$auth.userCan(this.$auth.Actions.CanListRoles)
                );
            },
            result({ data, error }) {
                if (!error) {
                    this.$store.dispatch(RolesAction.SetRoles, data.roles);
                }
            }
        },
        contentGenerationApiInfo: {
            query: getContentGenerationApiInfoQuery,
            skip() {
                return !this.isAuthorized;
            },
            result({ data }) {
                this.$store.commit(AuthMutation.SetContentGenerationApiInfo, {
                    contentGenerationApiInfo: data.contentGenerationApiInfo
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";
/////////////////////
// iview overrides
////////////////////
@import "../sass/iview-overrides";
/////////////////////
// main hoxton styles
////////////////////
@import "../sass/hoxton";

@import "../sass/theme.overwrite";
</style>

import { connectToParent } from "penpal";

/**
 *
 * @returns {Promise<string>}
 */
export async function getWppToken() {
    const connection = connectToParent({ timeout: 1500 });
    const { osApi } = await connection.promise;

    return osApi.getAccessToken();
}

<template>
    <collapsible-widget :is-opened="true">
        <template #name>After Effects Fonts</template>
        <div class="font-style">
            <editable-settings-control :title="camelToUpperCase(EditableSettingProperty.FontSize)">
                <template #button>
                    <div class="editable-settings-item">
                        <font-type width="20" height="20"></font-type>
                    </div>
                </template>
                <template #value>
                    <editable-settings-text-input
                        ref="font"
                        :key="getElKey(EditableSettingProperty.Font)"
                        :value="font"
                        prop-name="font"
                        :show-suffix="true"
                        suffix="name"
                        placeholder="Arial"
                        @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.Font, $event)"
                        @input="onStringInput(EditableSettingProperty.Font, $event)"
                    />
                </template>
            </editable-settings-control>
        </div>

        <editable-settings-control :title="camelToUpperCase(EditableSettingProperty.FontSize)">
            <template #button>
                <div class="editable-settings-item">
                    <letter-size width="16" height="16"></letter-size>
                </div>
            </template>
            <template #value>
                <editable-settings-number-input
                    ref="fontSize"
                    :key="getElKey(EditableSettingProperty.FontSize)"
                    :value="fontSize"
                    prop-name="fontSize"
                    :show-suffix="isSuffixVisble(EditableSettingProperty.FontSize)"
                    :placeholder="getPlaceholderValue(EditableSettingProperty.FontSize)"
                    @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.FontSize, $event)"
                    @input="onNumericInput(EditableSettingProperty.FontSize, $event)"
                />
            </template>
        </editable-settings-control>
    </collapsible-widget>
</template>
<script>
import LetterSize from "@/assets/text-editor/letter-size.svg";
import FontType from "@/assets/text-editor/type.svg";
import EditableSettingsControl from "@/components/Campaign/EditableSettingsControl";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { camelToUpperCase } from "@/utils";
import { EditableSettingProperty } from "@/enums/editables";
import editableSettingsMixin from "@/mixins/editableSettingsMixin";
import EditableSettingsNumberInput from "@/components/Campaign/EditableSettingsNumberInput";
import EditableSettingsTextInput from "@/components/Campaign/EditableSettingsTextInput";

export default {
    name: "FontStyleAfterEffects",
    components: {
        EditableSettingsNumberInput,
        EditableSettingsTextInput,
        CollapsibleWidget,
        EditableSettingsControl,
        LetterSize,
        FontType
    },

    mixins: [editableSettingsMixin],

    data() {
        return {
            relevantProps: [EditableSettingProperty.Font, EditableSettingProperty.FontSize],
            font: "",
            fontSize: undefined,
            camelToUpperCase,
            EditableSettingProperty
        };
    }
};
</script>

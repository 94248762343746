import { deepClone, kebabCase } from "@/utils";
import { EditableSettingProperty } from "@/enums/editables";

export default {
    props: {
        computedStyle: {
            required: true
        },

        isPsd: {
            type: Boolean,
            default: false
        },

        isAe: {
            type: Boolean,
            default: false
        },

        parentSettings: {
            type: Object,
            default() {
                return {};
            }
        },

        value: {
            type: Object
        }
    },

    data() {
        return {
            elKeys: {},
            steps: {}
        };
    },

    watch: {
        value: {
            immediate: true,
            handler(v) {
                const value = { ...v };

                this.resetStyles();
                this.resetValues(value);
            }
        }
    },

    created() {
        this.elKeys = this.relevantProps.reduce((acc, prop) => Object.assign(acc, { [prop]: 0 }), {});
    },

    methods: {
        clearStyles() {
            this.resetStyles();
            this.$emit("clear", this.css);
        },

        getCurrentValue(prop) {
            let value = this[prop];

            if (this[prop] === undefined) {
                value = this.getPlaceholderValue(prop) || 0;
            }

            return parseInt(value, 10);
        },

        getDomUnit(prop) {
            const domValue = this.getDomValue(prop);
            return !domValue || !domValue.toString().endsWith("px") ? "" : "px";
        },

        getDomValue(prop) {
            if (this.isPsd) {
                return undefined;
            }

            try {
                if (prop === EditableSettingProperty.FontColor) {
                    const domVal = this.computedStyle.getPropertyValue("color");
                    if (domVal.startsWith("rgb(")) {
                        return this.RGBToHex(domVal);
                    }

                    return domVal;
                }

                if (prop === EditableSettingProperty.Scale) {
                    const domVal = this.computedStyle.getPropertyValue("transform");
                    const cssValue = domVal.match(/scale\([^)]*\)(;|)?/);
                    if (cssValue.includes(",")) {
                        const firstValue = cssValue.split(",")[0];
                        return parseFloat(firstValue) * 100;
                    }

                    return parseFloat(cssValue[0]) * 100;
                }

                return this.computedStyle.getPropertyValue(kebabCase(prop));
            } catch (e) {
                return undefined;
            }
        },

        getElKey(prop) {
            return `key-${prop}-${this.elKeys[prop]}`;
        },

        getMoveByValue(event, step = 1) {
            let moveBy = event.key === "ArrowLeft" || event.key === "ArrowUp" ? step : -step;
            if (event.shiftKey) {
                moveBy *= 10;
            }

            return moveBy;
        },

        getPlaceholderValue(prop) {
            const value = Number.parseFloat(this.parentSettings[prop]) || Number.parseFloat(this.getDomValue(prop));

            if (Number.isNaN(value)) {
                if (prop === EditableSettingProperty.Scale) {
                    return "100";
                }
                return this.getDomValue(prop) || "";
            }
            return `${value}`;
        },

        hasValue(prop) {
            return this[prop] !== undefined && Number.isNaN(this[prop]);
        },

        isNumeric(val) {
            return !Number.isNaN(parseFloat(val)) && Number.isFinite(val);
        },

        isSuffixVisble(prop) {
            return this.getDomUnit(prop).length > 0 || this.getDomValue(prop) === undefined;
        },

        onInput(prop, value) {
            if (this[prop] === value) {
                return;
            }

            this[prop] = value;
            this.onStyleChange(prop);
        },

        onKeyboardUpdate(prop, event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
                event.stopImmediatePropagation();

                let moveBy = this.getMoveByValue(event);
                if (this.steps[prop]) {
                    moveBy *= this.steps[prop];
                }

                let currentValue = parseFloat(this[prop]);
                if (Number.isNaN(currentValue)) {
                    currentValue = Number.parseFloat(this.getPlaceholderValue(prop)) || 0;
                }

                this.onNumericInput(prop, currentValue + moveBy);
            }
        },

        async onNumericInput(prop, value) {
            const v = parseFloat(value);
            if (Number.isNaN(v) && this[prop]) {
                this.css[prop] = undefined;
                this[prop] = undefined;
                this.onStyleChange();
                this.elKeys[prop] += 1;
                await this.$nextTick();
                if (this.$refs[prop]) {
                    this.$refs[prop].focus();
                }

                return;
            }

            this.onInput(prop, v);
        },

        async onStringInput(prop, value) {
            const v = value.trim();
            this.onInput(prop, v);
        },

        onStyleChange(style) {
            if (style) {
                if (this.css[style] === this[style]) {
                    return;
                }

                this.css[style] = deepClone(this[style]);
            }

            const relevantParamUpdates = this.relevantProps.reduce((acc, prop) => {
                if (this.css[prop] !== undefined) {
                    return Object.assign(acc, { [prop]: this.css[prop] });
                }

                return acc;
            }, {});

            const update = {
                ...this.removeRelevantParams(this.value),
                ...relevantParamUpdates
            };

            this.$emit("input", update);
        },

        removeRelevantParams(v) {
            const { initial, ...rest } = v;
            this.relevantProps.forEach(p => delete rest[p]);

            return rest;
        },

        resetStyles() {
            this.css = this.relevantProps.reduce((acc, prop) => Object.assign(acc, { [prop]: undefined }), {});
        },

        resetValues(value) {
            this.relevantProps.forEach(prop => this.resetValue(prop, value));
        },

        resetValue(prop, values) {
            if (values[prop] !== undefined) {
                this[prop] = values[prop];
                this.css[prop] = values[prop];
            }
        },

        RGBToHex(_rgb) {
            // Choose correct separator
            const sep = _rgb.includes(",") ? "," : " ";
            // Turn "rgb(r,g,b)" into [r,g,b]
            const rgb = _rgb.substr(4).split(")")[0].split(sep);

            let r = parseInt(rgb[0], 10).toString(16);
            let g = parseInt(rgb[1], 10).toString(16);
            let b = parseInt(rgb[2], 10).toString(16);

            if (r.length === 1) {
                r = `0${r}`;
            }
            if (g.length === 1) {
                g = `0${g}`;
            }
            if (b.length === 1) {
                b = `0${b}`;
            }

            return `#${r}${g}${b}`;
        }
    }
};

// @ts-check
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";

import * as ciApi from "./CreativeIntelligenceApi";

export const PLAINLY_POLL_INTERVAL = 2000;

/**
 * we want to ignore the master template assets as the url is incorrect
 * plainly will rely on the default assets if we don't send a computed layer at all
 * @param {string} url
 * @returns {boolean}
 */
function isMasterTemplateAsset(url) {
    return url.includes("/master-templates/");
}

/**
 * @param {string} url
 */
function parseAssetUrl(url) {
    /** @type {[bucket: string, key: string]} */
    return url.split("?")[0].replace("https://", "").split(".s3.eu-west-1.amazonaws.com/");
}

/**
 * @param {Types.ComputedOverwrite[]} overwrites
 * @returns {ciApi.PlainlyComputedLayer[]}
 */
function getPlainlyComputedLayers(overwrites) {
    /** @type {ciApi.PlainlyComputedLayer[]} */
    const init = [];

    return overwrites.reduce((acc, { newValue, name, type, isPublisherPlatform, layerManagement }) => {
        if (
            newValue !== undefined &&
            newValue !== null &&
            newValue !== "" &&
            !isMasterTemplateAsset(newValue) &&
            !isPublisherPlatform
        ) {
            if (type === "image" || type === "video") {
                const [bucket, key] = parseAssetUrl(newValue);

                acc.push({
                    name,
                    fileLocation: {
                        bucket,
                        key
                    }
                });
            } else {
                acc.push({
                    name,
                    value: newValue,
                    layerManagement
                });
            }
        }

        return acc;
    }, init);
}

/**
 * @typedef {object} RequestRenderInput
 * @property {string} reportingLabel
 * @property {Types.AEInfo} aeInfo
 * @property {Types.ComputedOverwrite[]} computedOverwrites
 * @property {boolean} [preview]
 */

/**
 * @callback RequestRenderCallback
 * @param {RequestRenderInput} input
 * @returns {Promise<string|null>}
 */

/**
 * @callback ClearCallback
 * @returns {void}
 */

/**
 * @typedef {Object} AERenderServiceInstance
 * @property {ClearCallback} clear
 * @property {RequestRenderCallback} requestRender
 */

/**
 * @param {Object} params
 * @param {string} params.clientId
 * @param {Types.AeApiInfo} params.aeApiInfo
 * @returns {AERenderServiceInstance}
 */
export function AERenderService({ clientId, aeApiInfo: { baseUrl, apiKey } }) {
    if (!clientId || !baseUrl || !apiKey) {
        throw new Error("Invalid AE info");
    }

    /** @type {Map<symbol, string>} */
    const queue = new Map();

    /** @type {ClearCallback} */
    function clear() {
        queue.clear();
    }

    /** @type {RequestRenderCallback} */
    function requestRender({
        reportingLabel,
        aeInfo: { completed, plainlyProjectId, plainlyTemplateId },
        computedOverwrites,
        preview = false
    }) {
        return new Promise((resolve, reject) => {
            if (!completed || !plainlyProjectId || !plainlyTemplateId || !Array.isArray(computedOverwrites)) {
                reject(new Error("Invalid AE info"));

                return;
            }

            const computedLayers = getPlainlyComputedLayers(computedOverwrites);

            queue.forEach((label, requestId) => {
                if (label === reportingLabel) {
                    queue.delete(requestId);
                }
            });

            const requestId = Symbol(reportingLabel);
            queue.set(requestId, reportingLabel);

            async function pollApi() {
                if (!queue.has(requestId)) {
                    resolve(null);

                    return;
                }

                const { data } = await ciApi.createPlainlyRender({
                    baseUrl,
                    apiKey,
                    id: clientId,
                    input: {
                        reportingLabel,
                        plainlyProjectId,
                        plainlyTemplateId,
                        outputOptions: {
                            preview
                        },
                        computedLayers
                    }
                });

                switch (data.status) {
                    case ciApi.RESOURCE_MANAGER_STATUS.IN_PROGRESS:
                        setTimeout(pollApi, 2000);

                        return;

                    case ciApi.RESOURCE_MANAGER_STATUS.AVAILABLE:
                        queue.delete(requestId);

                        resolve(data?.output?.success?.fileLocation?.signedUrl ?? null);

                        return;

                    case ciApi.RESOURCE_MANAGER_STATUS.FAILED:
                    default:
                        queue.delete(requestId);

                        reject(new Error("There was an unexpected error while rendering the AE template"));
                }
            }

            pollApi();
        });
    }

    return {
        clear,
        requestRender
    };
}

/**
 * @param {object} params
 * @param {string} params.clientId
 * @param {Types.AeApiInfo} params.aeApiInfo
 * @param {RequestRenderInput[]} params.inputs
 * @returns {Promise<string|null>}
 */
export function requestBatchRender({ clientId, aeApiInfo: { baseUrl, apiKey }, inputs }) {
    return new Promise((resolve, reject) => {
        if (!clientId || !baseUrl || !apiKey) {
            reject(new Error("Invalid AE info"));

            return;
        }

        inputs.forEach(({ aeInfo: { completed, plainlyProjectId, plainlyTemplateId }, computedOverwrites }) => {
            if (!completed || !plainlyProjectId || !plainlyTemplateId || !Array.isArray(computedOverwrites)) {
                reject(new Error("Invalid AE info"));
            }
        });

        async function pollApi() {
            const { data } = await ciApi.createPlainlyBatchRender({
                baseUrl,
                apiKey,
                id: clientId,
                inputs: inputs.map(
                    ({ reportingLabel, aeInfo: { plainlyProjectId, plainlyTemplateId }, computedOverwrites }) => {
                        const computedLayers = getPlainlyComputedLayers(computedOverwrites);

                        return {
                            reportingLabel,
                            plainlyProjectId,
                            plainlyTemplateId,
                            outputOptions: {
                                preview: false
                            },
                            computedLayers
                        };
                    }
                )
            });

            switch (data.status) {
                case ciApi.RESOURCE_MANAGER_STATUS.IN_PROGRESS:
                    setTimeout(pollApi, PLAINLY_POLL_INTERVAL);

                    return;

                case ciApi.RESOURCE_MANAGER_STATUS.AVAILABLE:
                    resolve(data?.output?.success?.zipFileLocation?.signedUrl ?? null);

                    return;

                case ciApi.RESOURCE_MANAGER_STATUS.FAILED:
                default:
                    reject(new Error("There was an unexpected error while rendering the AE template"));
            }
        }

        pollApi();
    });
}

/**
 * CREATE PLAINLY PROJECT
 */

/**
 * @param {ciApi.ChildLayer[]} children
 * @returns {ciApi.ChildLayer[]}
 */
function getDynamicChildrenLayers(children) {
    return children.reduce(
        /**
         * @param {ciApi.ChildLayer[]} acc
         * @param {ciApi.ChildLayer} layer
         * @returns {ciApi.ChildLayer[]}
         */
        (acc, layer) => {
            if (layer.name.indexOf("$") === 0) {
                acc.push(layer);
            }

            if (layer.children?.length) {
                return acc.concat(getDynamicChildrenLayers(layer.children));
            }

            return acc;
        },
        []
    );
}

/**
 * @param {string} type
 * @param {string} [mediaType]
 * @returns {string}
 */
function mapAELayerTypeToEditableType(type, mediaType) {
    switch (type) {
        case "MEDIA":
            if (mediaType === "IMAGE") {
                return "image";
            }

            if (mediaType === "VIDEO") {
                return "video";
            }

            return "unknown";

        case "TEXT":
            return "text";

        default:
            return "unknown";
    }
}

/**
 * @param {Types.AeApiInfo} aeApiInfo
 * @param {ciApi.CreatePlainlyProjectSuccessResponse} data
 * @returns {Promise<Types.InputAEComposition[]>}
 */
async function createPlainlyTemplates(aeApiInfo, data) {
    /**
     * @typedef {Object} CompositionTemplate
     * @property {ciApi.CompositionLayer} compositionLayer
     * @property {ciApi.ChildLayer[]} childLayers
     */

    /** @type {CompositionTemplate[]} */
    const init = [];

    const compositionTemplates = data.output.success.layers.reduce((acc, layer) => {
        if (layer.type === "COMPOSITION" && layer.name.indexOf("$") === 0 && layer.children?.length) {
            const childLayers = getDynamicChildrenLayers(layer.children);

            acc.push({
                compositionLayer: layer,
                childLayers
            });
        }

        return acc;
    }, init);

    const plainlyTemplates = compositionTemplates.map(({ compositionLayer, childLayers }) => {
        const plainlyChildLayers = childLayers.map(childLayer => {
            const [, first, second] = childLayer.name.split("$");

            return {
                id: childLayer.internalId,
                name: second ?? first
            };
        });

        return {
            name: compositionLayer.name.split("$")[1],
            renderingCompositionId: Number(compositionLayer.internalId),
            layers: plainlyChildLayers
        };
    });

    const { data: plainlyTemplateIds } = await ciApi.createPlainlyTemplates({
        baseUrl: aeApiInfo.baseUrl,
        apiKey: aeApiInfo.apiKey,
        resourceId: data.resourceId,
        templates: plainlyTemplates
    });

    /** @type {Types.InputAEComposition[]} */
    const compositions = compositionTemplates.map(({ compositionLayer, childLayers }, index) => {
        const layers = childLayers.map(childLayer => {
            const [, first, second] = childLayer.name.split("$");

            const name = second ?? first;
            const group = second ? first : "generic";

            const editableType = mapAELayerTypeToEditableType(childLayer.type, childLayer.mediaType);

            return {
                name,
                label: name,
                group,
                type: editableType,
                // we ignore the project's internal file path values for images and videos
                defaultValue: editableType === "text" ? childLayer.value : ""
            };
        });

        return {
            name: compositionLayer.name.split("$")[1],
            width: compositionLayer.width,
            height: compositionLayer.height,
            plainlyTemplateId: plainlyTemplateIds[index],
            layers
        };
    });

    return compositions;
}

/**
 * @param {Object} params
 * @param {string} params.clientId
 * @param {string} params.persistentBucket
 * @param {Types.AeApiInfo} params.aeApiInfo
 * @param {string} params.key
 * @returns {Promise<{ plainlyProjectId: string; compositions: Types.InputAEComposition[] }>}
 */
export function createAEProject({ clientId, persistentBucket, aeApiInfo, key }) {
    return new Promise((resolve, reject) => {
        if (!aeApiInfo.baseUrl || !aeApiInfo.apiKey || !clientId || !persistentBucket || !key) {
            reject(new Error("Invalid AE info"));

            return;
        }

        async function pollApi() {
            const { data } = await ciApi.createPlainlyProject({
                baseUrl: aeApiInfo.baseUrl,
                apiKey: aeApiInfo.apiKey,

                id: clientId,
                bucket: persistentBucket,

                key
            });

            switch (data.status) {
                case ciApi.RESOURCE_MANAGER_STATUS.IN_PROGRESS:
                    setTimeout(pollApi, PLAINLY_POLL_INTERVAL);

                    return;

                case ciApi.RESOURCE_MANAGER_STATUS.AVAILABLE: {
                    const compositions = await createPlainlyTemplates(aeApiInfo, data);

                    resolve({ plainlyProjectId: data.output.success.plainlyProjectId, compositions });

                    return;
                }

                case ciApi.RESOURCE_MANAGER_STATUS.FAILED:
                default:
                    reject(new Error("Failed to create plainly project."));
            }
        }

        pollApi();
    });
}

// eslint-disable-next-line import/no-extraneous-dependencies
import { SuperAdminRole } from "shared-utils/enums/user";

const storeNamespace = "auth";

const InternalAuthMutation = {
    SetCreated: "setCreated",
    SetIsAuthorized: "setIsAuthorized",
    SetMe: "setMe",
    SetContentGenerationApiInfo: "setContentGenerationApiInfo",
    SetIsWppLogin: "setIsWppLogin"
};

export const AuthMutation = {
    SetCreated: `${storeNamespace}/${InternalAuthMutation.SetCreated}`,
    SetIsAuthorized: `${storeNamespace}/${InternalAuthMutation.SetIsAuthorized}`,
    SetMe: `${storeNamespace}/${InternalAuthMutation.SetMe}`,
    SetContentGenerationApiInfo: `${storeNamespace}/${InternalAuthMutation.SetContentGenerationApiInfo}`,
    SetIsWppLogin: `${storeNamespace}/${InternalAuthMutation.SetIsWppLogin}`
};

export const AuthGetters = {
    hasScope: `${storeNamespace}/hasScope`,
    isSuperAdmin: `${storeNamespace}/isSuperAdmin`,
    userCan: `${storeNamespace}/userCan`,
    userHasAction: `${storeNamespace}/userHasAction`,
    contentGenerationApiInfo: `${storeNamespace}/contentGenerationApiInfo`,
    isWppLogin: `${storeNamespace}/isWppLogin`
};

const isSuperAdmin = state => state.me && state.me.role && state.me.role.name === SuperAdminRole;

export default {
    namespaced: true,
    state: {
        isAuthorized: false,
        me: null,
        contentGenerationApiInfo: "",
        isWppLogin: false
    },

    getters: {
        hasScope:
            state =>
            // eslint-disable-next-line complexity
            (scope, exact = true) => {
                // If the user is a Super Admin grant full access
                if (isSuperAdmin(state)) {
                    return true;
                }
                // If actions on the is an array then look for the passed action otherwise permit no access
                const hasClientScope = !!(state.me.scope.client && state.me.scope.client.includes(scope.clientId));

                if (typeof scope.campaignId === "undefined" && exact) {
                    return hasClientScope;
                }

                let hasCampaignScope = !!(
                    state.me.scope.campaign && state.me.scope.campaign.includes(scope.campaignId)
                );

                if (!exact && !hasCampaignScope) {
                    hasCampaignScope = !!(
                        state.me.scope.campaignOnlyClient && state.me.scope.campaignOnlyClient.includes(scope.clientId)
                    );
                }

                return hasClientScope || hasCampaignScope;
            },

        isSuperAdmin,

        userCan: (state, getters) => action => {
            // If the user is a Super Admin grant full access
            if (isSuperAdmin(state)) {
                return true;
            }

            return getters.userHasAction(action);
        },

        contentGenerationApiInfo(state) {
            return state.contentGenerationApiInfo;
        },

        userHasAction: state => action =>
            state.me && state.me.role && Array.isArray(state.me.role.actions) && state.me.role.actions.includes(action),

        isWppLogin: state => state.isWppLogin
    },

    mutations: {
        /**
         * @param {State} state
         * @param {boolean} payload
         */
        setIsAuthorized(state, payload) {
            state.isAuthorized = payload;
        },

        setMe(state, payload) {
            state.me = payload.me;
        },

        setContentGenerationApiInfo(state, payload) {
            state.contentGenerationApiInfo = payload.contentGenerationApiInfo;
        },

        /**
         * @param {State} state
         * @param {boolean} payload
         */
        setIsWppLogin(state, payload) {
            state.isWppLogin = payload;
        }
    }
};

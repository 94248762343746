<template>
    <collapsible-widget collapsed-height="47" :is-opened="opened" @input="toggleOpen">
        <template #name>{{ editable.label }}</template>
        <template #controls>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <div class="deliverable-values editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <expandable-text-input
                class="deliverable-value-item"
                :label="EditableGroupScopeLabel"
                :is-expanded="isExpanded(null)"
                :type="inputType"
                :value="getEditableValue(editable, groupsAffectingEditable, null)"
                :settings="getEditableSettings(editable, groupsAffectingEditable, null)"
                :scope="OverwriteScope.EditableGroup"
                :overwrite="getOverwrite(editable, groupsAffectingEditable, null)"
                :placeholder="groupPlaceholder"
                :lockable="false"
                @collapse="onCollapse(editable._id, null)"
                @input="throttledValueChange($event)"
                @expand="onExpand(editable, groupsAffectingEditable, null)"
                @restore="onGroupValueRestore($event)"
                @updateSettings="onUpdateSettings(null, ...arguments)"
            />

            <expandable-text-input
                v-for="(label, templateId) in templateLabels"
                v-show="opened"
                :key="templateId"
                class="deliverable-value-item"
                :is-expanded="isExpanded(templateId)"
                :label="label"
                :label-color="getLabelColor(editable, groupsAffectingEditable, templateId)"
                :type="inputType"
                :value="getEditableValue(editable, groupsAffectingEditable, templateId)"
                :settings="getEditableSettings(editable, groupsAffectingEditable, templateId)"
                :scope="OverwriteScope.EditableGroupTemplate"
                :overwrite="getOverwrite(editable, groupsAffectingEditable, templateId)"
                placeholder="Enter value"
                @collapse="onCollapse(editable._id, templateId)"
                @input="throttledValueChange($event, templateId)"
                @expand="onExpand(editable, groupsAffectingEditable, templateId)"
                @removeOverwrite="onRemoveOverwrite"
                @restore="onValueRestore($event, templateId)"
                @updateSettings="onUpdateSettings(templateId, ...arguments)"
            />
        </div>
    </collapsible-widget>
</template>

<script>
import throttle from "lodash.throttle";

import editableUtils from "@/utils/editables";

import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import ExpandableTextInput from "@/components/Campaign/ExpandableTextInput";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";
import { EditorAction } from "@/store/modules/editor";
import { OverwriteScope } from "@/enums/overwrites";

export default {
    name: "EditableTextWidget",
    components: {
        CollapsibleWidget,
        EditableRestrictionControl,
        ExpandableTextInput
    },

    mixins: [editableItemMixin, editableMethodsMixin],

    computed: {
        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        editedEditable() {
            return this.$store.state.editor.editedEditable;
        },

        editedEditablesSettings() {
            return this.$store.state.editor.editedEditablesSettings;
        },

        groupPlaceholder() {
            const masterTemplateIds = Object.keys(this.templateLabels);
            return editableUtils.getGroupPlaceholder(this.editable, masterTemplateIds, "Enter value");
        },

        inputType() {
            return "textarea";
            // return this.editable.type === EditableType.Textarea ? "textarea" : "text";
        },

        isEdited() {
            return this.editedEditable && this.editedEditable.editable._id === this.editable._id;
        },

        isEditablesPaneVisible() {
            return this.$store.state.editor.isEditablesPaneVisible;
        },

        language() {
            return this.$store.state.editor.selectedLanguage;
        }
    },

    created() {
        this.OverwriteScope = OverwriteScope;
        this.throttledValueChange = throttle((value, templateId) => {
            this.onValueChange(value, templateId);
        }, 100);
    },

    methods: {
        isExpanded(templateId) {
            return this.isEdited && this.editedEditable.masterTemplateId === templateId;
        },

        onAllChange(value) {
            this.update({
                value
            });
        },

        onCollapse(editableId, templateId) {
            if (this.isExpanded(templateId)) {
                this.$store.dispatch(EditorAction.SetEditedEditable, null);
            }
        },

        onExpand(editable, groupsAffectingEditable, templateId) {
            this.$store.dispatch(EditorAction.SetEditedEditable, {
                editable,
                masterTemplateId: templateId,
                groupsAffectingEditable
            });

            this.$store.dispatch(EditorAction.SetSettingsPaneVisibility, true);
        },

        onGroupValueRestore(value) {
            this.restore({
                value,
                language: this.language
            });
        },

        onRemoveOverwrite(overwriteId) {
            this.removeOverwrite(overwriteId);
        },

        onValueChange(value, templateId) {
            this.update(
                {
                    value
                },
                templateId
            );
        },

        onValueRestore(value, templateId) {
            this.restore({
                value,
                templateId,
                language: this.language
            });
        }
    }
};
</script>

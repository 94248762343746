<template>
    <hox-modal
        :show-footer="!isLoadingCampaign || !hasErrorLoadingCampaign"
        data-testid="edit-campaign-modal"
        @close="close"
    >
        <hox-loading-layer v-if="isUpdatingCampaign" />
        <template #header>Ad server link</template>
        <div class="edit-campaign-modal__info">Please add details to make ad set link</div>
        <div v-if="isLoadingCampaign" class="edit-campaign-modal__loading-container">
            <hox-loading-layer :is-full-screen="false" />
        </div>
        <hox-alert v-else-if="hasErrorLoadingCampaign" margin-bottom="none" type="danger">
            <template #title>Uh oh!</template>
            <template #content>
                <p>Something went wrong while getting some data. Try again in a few moments.</p>
            </template>
            <template #actionItems>
                <Button type="primary" @click="refetchCampaign">Retry</Button>
            </template>
        </hox-alert>
        <template v-else>
            <Form
                ref="editOpenDcCampaignForm"
                :rules="validationRules"
                :model="localCampaignData"
                @submit.native.prevent="onUpdateCampaign"
            >
                <FormItem ref="feedId" label="Feed id" prop="feedId">
                    <Input
                        v-model="localCampaignData.feedId"
                        auto-complete="off"
                        data-testid="edit-campaign__feed-id"
                        placeholder="Enter a feed id"
                    ></Input>
                </FormItem>
                <FormItem ref="advertiserId" label="Advertiser id" prop="advertiserId">
                    <Input
                        v-model="localCampaignData.advertiserId"
                        auto-complete="off"
                        data-testid="edit-campaign__advertiser-id"
                        placeholder="Enter an advertiser id"
                    ></Input>
                </FormItem>
                <FormItem ref="campaignId" label="Campaign id" prop="campaignId">
                    <Input
                        v-model="localCampaignData.campaignId"
                        auto-complete="off"
                        data-testid="edit-campaign__campaign-id"
                        placeholder="Enter an Campaign id"
                    ></Input>
                </FormItem>
                <FormItem ref="adsetId" label="Adset id" prop="adsetId">
                    <Input
                        v-model="localCampaignData.adsetId"
                        auto-complete="off"
                        data-testid="edit-campaign__adset-id"
                        placeholder="Enter an adset id"
                    ></Input>
                </FormItem>
            </Form>
            <div v-if="hasUnexpectedErrorUpdatingCampaign" class="edit-campaign-modal__unexpected-error-wrapper">
                <Alert banner type="error">
                    <template #desc>
                        There was an unexpected error and the campaign was not updated. Please try again in a few
                        moments.
                    </template>
                </Alert>
            </div>
            <template slot="footer">
                <div class="edit-campaign-modal__buttons-container">
                    <Button
                        type="primary"
                        :disabled="isUpdatingCampaign"
                        data-testid="edit-campaign-modal__save-button"
                        @click="onUpdateCampaign"
                    >
                        Save Association
                    </Button>
                </div>
            </template>
        </template>
    </hox-modal>
</template>

<script>
import updateCampaignMutation from "@/apollo/mutations/UpdateCampaign.gql";
import { groupErrorsByField } from "@/utils";

export default {
    name: "OpendcSettingsModal",

    props: {
        campaignId: {
            required: true,
            type: String
        },

        campaignData: {
            type: Object
        }
    },

    data() {
        return {
            campaign: undefined,
            hasErrorLoadingCampaign: false,
            hasUnexpectedErrorUpdatingCampaign: false,
            isLoadingCampaign: 0,
            isUpdatingCampaign: false,
            mutationErrors: {},
            uploadConfig: undefined,
            localCampaignData: { ...this.campaignData }
        };
    },

    computed: {
        canEditCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanEditCampaign, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        userCanDeleteCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanRemoveCampaign, {
                campaignId: this.campaignId,
                clientId: this.clientId
            });
        },

        validationRules() {
            return {
                feedId: [
                    {
                        required: true,
                        message: "Please enter a feed id.",
                        trigger: "blur"
                    }
                ],
                advertiserId: [
                    {
                        required: true,
                        message: "Please enter an advertiser id.",
                        trigger: "change"
                    }
                ],
                campaignId: [
                    {
                        required: true,
                        message: "Please enter an campaign id.",
                        trigger: "change"
                    }
                ],
                adsetId: [
                    {
                        required: true,
                        message: "Please enter an adset id.",
                        trigger: "change"
                    }
                ]
            };
        }
    },

    watch: {
        canEditCampaign() {
            if (!this.canEditCampaign) {
                this.$snackbar.warning("You do not have access to edit campaigns");
                this.close();
            }
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        refetchCampaign() {
            this.$apollo.queries.campaign.refetch();
        },

        onUpdateCampaign() {
            this.$refs.editOpenDcCampaignForm.validate(valid => {
                if (valid) {
                    this.updateCampaign();
                }
            });
        },
        async updateCampaign() {
            this.isUpdatingCampaign = true;
            try {
                await this.$apollo.mutate({
                    mutation: updateCampaignMutation,
                    variables: {
                        campaignId: this.campaignId,
                        campaign: {
                            opendcPlatformSettings: {
                                feedId: this.localCampaignData.feedId,
                                advertiserId: this.localCampaignData.advertiserId,
                                campaignId: this.localCampaignData.campaignId,
                                adsetId: this.localCampaignData.adsetId
                            }
                        }
                    }
                });
                this.$snackbar.success("Campaign updated");
                this.$emit("campaignEdited");
                this.close();
            } catch ({ graphQLErrors }) {
                this.mutationErrors = groupErrorsByField(graphQLErrors);
                this.hasUnexpectedErrorUpdatingCampaign = Object.keys(this.mutationErrors).length === 0;
            } finally {
                this.isUpdatingCampaign = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.edit-campaign-modal {
    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;
    }

    &__info {
        padding-bottom: $spacing-smaller;
        font-weight: bold;
    }
}
</style>

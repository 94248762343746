<template>
    <div class="change-password-wrapper">
        <hogarth-h class="logo" />
        <h1>Reset Password</h1>
        <Form direction="vertical" class="change-password-form" @submit.native.prevent>
            <FormItem v-if="$route.params.username" label="Code">
                <Input
                    v-model="form.code"
                    type="text"
                    placeholder="Code"
                    size="large"
                    @keyup.native.enter="changePassword"
                ></Input>
            </FormItem>
            <FormItem label="New Password">
                <Input
                    v-model="form.password"
                    type="password"
                    placeholder="New Password"
                    size="large"
                    @keydown.native.stop
                    @keyup.native.enter="changePassword"
                ></Input>
            </FormItem>
            <FormItem label="Confirm New Password">
                <Input
                    v-model="form.confirmPassword"
                    type="password"
                    placeholder="Confirm New Password"
                    size="large"
                    @keydown.native.stop
                    @keyup.native.enter="changePassword"
                ></Input>
            </FormItem>
            <FormItem class="change-password-btn-wrapper">
                <Button type="primary" size="large" class="change-password-btn primary-green" @click="changePassword">
                    Save
                </Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import HogarthH from "@/assets/Hogarth_H.svg";
import bus from "@/bus";

import { authN } from "@/router";

export default {
    name: "ResetPassword",
    components: {
        HogarthH
    },
    data() {
        return {
            form: {
                code: "",
                password: "",
                confirmPassword: ""
            }
        };
    },

    mounted() {
        if (!this.$route.params.username && !this.$route.params.forceResetPassword) {
            this.$router.push({ name: "Login" });
        }
    },

    methods: {
        ...mapMutations("auth", ["setIsAuthorized"]),
        validateChangePassword() {
            if (this.$route.params.username && this.form.code.length === 0) {
                this.$snackbar.fieldRequired("Code must not be empty");
                return false;
            }

            if (this.form.password.length === 0) {
                this.$snackbar.fieldRequired("Password must not be empty");
                return false;
            }

            if (this.form.password !== this.form.confirmPassword) {
                this.$snackbar.error("Password does not match", "Confirm Password must match the Password");
                return false;
            }
            return true;
        },
        async changePassword() {
            if (!this.validateChangePassword()) {
                return;
            }
            try {
                bus.$emit("percentLoadEvent", 50);
                // Call forgot password submit if username in params
                if (this.$route.params.username) {
                    await this.$auth.forgotPasswordSubmit(
                        this.$route.params.username,
                        this.form.code,
                        this.form.password
                    );
                    this.$snackbar.success("Password Changed", "Your password has been successfully updated.");
                    bus.$emit("percentLoadEvent", 100);
                    this.setIsAuthorized(false);
                    this.$router.push({ name: "Login" });
                } else {
                    await this.$auth.completeNewPassword(this.form.password);
                    bus.$emit("percentLoadEvent", 100);
                    this.setIsAuthorized(true);
                    this.$router.push(authN(this.$route));
                }
            } catch (e) {
                bus.$emit("percentLoadEvent", 100);
                this.$snackbar.authentication(e);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/variables";

.change-password {
    height: auto;
    margin-top: 25px;
    padding: 10px 50px;

    &-form {
        width: 100%;
    }

    &-wrapper {
        width: 360px;
        text-align: center;
        min-height: 80vh;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h1 {
            color: $white;
            font-size: 32px;
            margin-bottom: $spacing-large;
        }

        .change-password-btn {
            width: 100%;

            &-wrapper {
                margin-top: 24px;
            }
        }

        .logo {
            width: 100px;
            margin-top: 120px;
            margin-bottom: $spacing-largest;
        }
    }
}
</style>

import Vue from "vue";
import Router from "vue-router";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserActions } from "shared-utils/enums/user";
import store from "@/store";
import Clients from "@/views/Clients";
import CampaignsDashboard from "@/views/CampaignsDashboard";
import FontsDashboard from "@/views/FontsDashboard";
import PageNotFound from "@/views/PageNotFound";
import UserNotFound from "@/views/UserNotFound";
import Login from "@/views/Login";
import ResetPassword from "@/views/ResetPassword";
import Maintenance from "@/views/Maintenance";
import CampaignEdit from "@/views/Campaign/Edit";
import CampaignLibrary from "@/views/Campaign/Library";
import CampaignTemplates from "@/views/Campaign/Templates";
import ReportingInsights from "@/views/CreativeInteligence/Insights";
import NetworkError from "@/views/NetworkError";
import LocalDevNetworkError from "@/views/LocalDevNetworkError";
import InsightsDashboard from "@/views/CreativeInteligence/InsightsDashboard";
import TagInsights from "@/views/CreativeInteligence/TagInsights";
import IntelligentDelivery from "@/views/IntelligentDelivery/IntelligentDelivery";

import { MediaLibraryAction } from "../store/modules/mediaLibrary";

const PrefixComponent = {
    name: "prefix-component",
    render(c) {
        return c("router-view");
    }
};

Vue.use(Router);

const domainRoutes = [
    {
        path: "/",
        name: "Home",
        component: Clients
    },
    {
        path: "/client/:clientId",
        component: PrefixComponent,
        meta: { breadcrumb: ":client" },
        children: [
            {
                path: "/",
                name: "CampaignsDashboard",
                component: CampaignsDashboard
            },
            {
                path: "templates",
                name: "ClientTemplates",
                component: CampaignTemplates,
                meta: {
                    theme: "templates-light",
                    breadcrumb: "Master Templates",
                    permission: {
                        action: UserActions.CanListMasterTemplates,
                        scope: {
                            clientId: true
                        }
                    }
                }
            },
            {
                path: "fonts",
                name: "Fonts",
                component: FontsDashboard,
                permission: {
                    action: UserActions.CanManageFonts,
                    scope: {
                        clientId: true
                    }
                }
            },
            {
                path: "campaign/:campaignId",
                component: PrefixComponent,
                meta: {
                    breadcrumb: ":campaign",
                    permission: {
                        action: UserActions.CanManageOverwrites,
                        scope: {
                            clientId: true,
                            campaignId: true
                        }
                    }
                },
                children: [
                    {
                        path: "/",
                        name: "Campaign",
                        redirect: { name: "CampaignLibrary" }
                    },
                    {
                        path: "editor",
                        name: "CampaignEditor",
                        component: CampaignEdit,
                        meta: {
                            theme: "campaign-light",
                            breadcrumb: "Assemble"
                        }
                    },
                    {
                        path: "library",
                        name: "CampaignLibrary",
                        component: CampaignLibrary,
                        meta: {
                            theme: "campaign-light",
                            breadcrumb: "Creative Library"
                        }
                    },
                    {
                        path: "connect",
                        name: "IntelligentDelivery",
                        component: IntelligentDelivery,
                        meta: {
                            theme: "campaign-light",
                            breadcrumb: "Connect"
                        }
                    },
                    {
                        path: "creative-intelligence",
                        name: "ReportingDashboardWrapper",
                        component: PrefixComponent,
                        meta: {
                            theme: "creative-insights"
                        },
                        children: [
                            {
                                path: "insights",
                                name: "CIInsights",
                                component: ReportingInsights,
                                meta: {
                                    theme: "creative-insights",
                                    breadcrumb: "Dashboard"
                                }
                            },
                            {
                                path: "",
                                name: "CIInsightsDashboard",
                                component: InsightsDashboard,
                                meta: {
                                    theme: "creative-insights",
                                    breadcrumb: "Dashboard"
                                }
                            },
                            {
                                path: "tag/:tagId",
                                name: "CITagInsights",
                                component: TagInsights,
                                meta: {
                                    theme: "creative-insights",
                                    breadcrumb: "Dashboard"
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "Clients",
            redirect: { name: "Home" }
        },
        ...domainRoutes,
        {
            path: "/maintenance",
            name: "Maintenance",
            component: Maintenance
        },
        {
            path: "/login",
            name: "Login",
            component: Login,
            meta: { theme: "login" }
        },
        {
            path: "/reset-password/:username?",
            name: "Reset Password",
            component: ResetPassword,
            meta: { theme: "login" }
        },
        {
            path: "/sandbox",
            name: "Sandbox",
            component: CampaignEdit,
            meta: { theme: "campaign-dark", hideCampaignNav: true },
            props: { sandboxMode: true }
        },
        {
            path: "/user-not-found",
            name: "UserNotFound",
            component: UserNotFound
        },
        {
            path: "/network-error",
            name: "NetworkError",
            component: NetworkError
        },
        {
            path: "/local-dev-network-error",
            name: "LocalDevNetworkError",
            component: LocalDevNetworkError
        },
        {
            path: "*",
            component: PageNotFound
        }
    ]
});

export function authN(route) {
    const rootRoute = { name: "Home", meta: { theme: "default" } };

    const ignoredRoutes = ["Login", "Reset Password", "UserNotFound", "NetworkError"];

    if (store.state.auth.isAuthorized && ["Login", "Reset Password"].includes(route.name)) {
        const { loginReturnRoute } = store.state.ui;

        if (loginReturnRoute) {
            store.commit("ui/setLoginReturnRoute", null);

            return loginReturnRoute;
        }

        return { ...rootRoute, query: route.query };
    }

    if (!store.state.auth.isAuthorized && route.name !== "Reset Password") {
        if (!ignoredRoutes.includes(route.name)) {
            store.commit("ui/setLoginReturnRoute", { ...route });
        }

        return { name: "Login", query: route.query };
    }

    return false;
}

router.getLandingRoute = route => {
    return authN(route) || { name: route.name, query: route.query };
};

/**
 *  We pass through if we detect the `code` query param as it is used for authN.

 *  If we did not do this, the subsequent code would strip the query params out
 *  before the app had been initialised, meaning that SSO users would be unable
 *  to login.
 */
const keepQuery = query => query.iss || query.error_description || query.code;

export const beforeEachGuard = (to, from, next) => {
    store.dispatch("ui/setTheme", to.meta.theme ? to.meta.theme : store.state.ui.defaultTheme);

    if (keepQuery(to.query) || store.state.isMaintenanceMode) {
        return next();
    }

    const authNRedirectRoute = authN(to);

    if (authNRedirectRoute && authNRedirectRoute.name !== to.name) {
        if (authNRedirectRoute?.meta?.theme) {
            store.dispatch("ui/setTheme", authNRedirectRoute.meta.theme);
        }

        return next(authNRedirectRoute);
    }

    if (to.name === "NetworkError") {
        store.commit("ui/setNetworkErrorReturnRoute", from);
    }

    return next();
};

router.beforeEach(beforeEachGuard);

// Store clearance. If the current client changes then we can reset parts of the store here.
router.beforeEach((to, from, next) => {
    // We can only be changing the client if there is a clientId in to.params
    if (to.params.clientId) {
        // If there is no current client set OR the id in to.params is different to that of the current client then we are switching
        if (!store.state.ui.currentClient || store.state.ui.currentClient._id !== to.params.clientId) {
            store.dispatch(MediaLibraryAction.ResetState);
            // TODO: Clear apollo cache? Clear other states?
        }
    }

    next();
});

export default router;

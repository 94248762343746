<template>
    <!--
    We use two containers so that we can correctly scale the iframe.

    If we apply `bannerWrapperStyle` on the iframe, it causes it to break (incorrect width).
  -->
    <div
        :style="containerStyle"
        class="banner-component-wrapper"
        :class="{ 'banner-component-wrapper--resizable': canResize }"
    >
        <div v-if="isPsd">
            <div v-if="isLoading || !psdPreviewUrl" class="banner__loading-spinner-wrapper">
                <hox-loading-spinner v-if="isLoading" :size="40" />
                <Icon v-else type="md-image"></Icon>
            </div>
            <img v-else :src="psdPreviewUrl" :alt="id" class="psd-preview" />
        </div>

        <div v-if="isHTML5" :style="bannerWrapperStyle" class="bannerWrapper">
            <iframe
                :id="id"
                ref="iframe"
                :name="id"
                :width="width"
                :height="height"
                frameborder="0"
                class="banner-iframe"
            />

            <form ref="postForm" :action="previewUrl" :target="id" method="POST">
                <input type="hidden" name="computedOverwrites" :value="computedOverwritesFormData" />
                <input type="hidden" name="metadata" :value="metadataFormData" />
            </form>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import isAbsoluteUrl from "shared-utils/isAbsoluteUrl";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";

import renderPsdTemplateMutation from "@/apollo/mutations/RenderPsdTemplate.gql";
import { BannerEvents } from "@/enums/banners";
import { BannersAction } from "@/store/modules/banners.store";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Banner",
    props: {
        width: {
            required: true,
            type: [String, Number]
        },
        height: {
            required: true,
            type: [String, Number]
        },
        id: {
            required: true,
            type: String
        },
        previewValues: {
            required: true,
            type: String
        },
        debug: {
            type: Boolean,
            default: false
        },
        scalingFactor: {
            default: 1,
            type: Number
        },
        adType: {
            type: String,
            default: TemplateType.HTML5
        },
        isResizable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoading: true,
            psdPreviewUrl: null
        };
    },

    computed: {
        bannerWrapperStyle() {
            if (this.isResizable) {
                return {
                    height: "100%",
                    width: "100%"
                };
            }

            const style = {
                height: `${this.height}px`,
                width: `${this.width}px`
            };
            if (this.scalingFactor < 1) {
                style.transform = `scale(${this.scalingFactor})`;
                style.transformOrigin = "0 0";
            }
            return style;
        },

        canResize() {
            return this.isResizable && this.scalingFactor === 1;
        },

        containerStyle() {
            if (this.canResize) {
                return {
                    height: "100%",
                    width: "100%",
                    overflow: "auto"
                };
            }

            return {
                height: `${this.height * this.scalingFactor}px`,
                width: `${this.width * this.scalingFactor}px`,
                overflow: "hidden"
            };
        },

        isPsd() {
            return this.adType === TemplateType.PSD;
        },

        isHTML5() {
            return this.adType === TemplateType.HTML5;
        },

        data() {
            return JSON.parse(this.previewValues);
        },

        hasComputedOverwrites() {
            return Array.isArray(this.data.computedOverwrites) && this.data.computedOverwrites.length;
        },

        computedOverwritesFormData() {
            return JSON.stringify(this.data.computedOverwrites);
        },

        metadataFormData() {
            return JSON.stringify(this.data.metadata);
        },

        computedLayers() {
            return this.data.computedOverwrites.map(({ newValue, type, layer }) => ({
                layer,
                type,
                value: newValue
            }));
        },

        previewUrl() {
            if (this.isPsd) {
                return this.data.psdThumbnail || `${this.data.previewUrl}/preview.png`;
            }

            if (isAbsoluteUrl(this.data.previewUrl)) {
                return this.data.previewUrl;
            }

            return `${this.data.previewUrl}/index.html`;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        }
    },

    created() {
        this.$store.dispatch(BannersAction.Init, this.id);
    },

    async mounted() {
        if (this.$refs.iframe) {
            // If we add this as an attribute to the iframe tag then we get a
            //  "Function statements require a function name" error
            this.$refs.iframe.onload = this.iframeLoaded;
            this.$refs.postForm.submit();
        } else if (this.isPsd) {
            try {
                this.isLoading = true;

                if (this.hasComputedOverwrites) {
                    const { data } = await this.$apollo.mutate({
                        mutation: renderPsdTemplateMutation,
                        variables: {
                            campaignId: this.data.campaignId,
                            computedLayers: this.computedLayers,
                            masterTemplate: {
                                _id: this.data.masterTemplate._id,
                                persistentKey: this.data.masterTemplate.persistentKey,
                                width: this.width,
                                height: this.height,
                                collapsed: this.data.masterTemplate.collapsed,
                                backupQuality: this.data.masterTemplate.backupQuality
                            }
                        }
                    });
                    this.psdPreviewUrl = data.renderPsdTemplate;
                } else {
                    this.psdPreviewUrl = this.previewUrl;
                }
            } catch (err) {
                this.psdPreviewUrl = null;
                this.$snackbar.error("There was an unexpected error while rendering the PSD template");
            } finally {
                this.isLoading = false;
            }
        }
    },
    methods: {
        iframeLoaded(evt) {
            // Disable alert and console and functions from inside the iframe to stop banner logs running in Hoxton
            try {
                const originalConsole = this.$refs.iframe.contentWindow.console;
                this.$refs.iframe.contentWindow.alert = () => "";
                this.$refs.iframe.contentWindow.console = {
                    error: () => "",
                    info: () => "",
                    log: () => "",
                    warn: () => ""
                };

                if (this.debug) {
                    const bannerLabel = `🔔 ${this.width}x${this.height} - ${this.id}:`;

                    this.$refs.iframe.contentWindow.console = {
                        error: (...args) => {
                            originalConsole.error(bannerLabel, ...args);
                        },
                        info: (...args) => {
                            originalConsole.info(bannerLabel, ...args);
                        },
                        log: (...args) => {
                            originalConsole.log(bannerLabel, ...args);
                        },
                        warn: (...args) => {
                            originalConsole.warn(bannerLabel, ...args);
                        }
                    };
                }
            } catch (e) {
                if (e.message.includes("Blocked a frame with origin")) {
                    console.error(e);
                    console.error(
                        "Parent host",
                        window.location.origin,
                        "Parent document domain",
                        document.domain,
                        "Preview URL",
                        this.previewUrl
                    );
                }
            }

            // iframe loaded
            this.isLoading = false;
            this.$emit(BannerEvents.BannerIFrameLoaded, evt);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.banner__loading-spinner-wrapper {
    color: $cmp-light-font-color;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.psd-preview {
    width: 100%;
}

.banner-component-wrapper--resizable .bannerWrapper {
    overflow: hidden;
    position: relative;

    .banner-iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.bannerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-iframe {
        margin: 0;
        padding: 0;
        background-color: $banner-preview-bg-color;
    }
}
</style>

<template>
    <hox-modal class="importExportModal" :wrapper-class="modalClass" @close="close">
        <template #header>Connect Feed</template>
        <div class="importExportModal__subtitle">
            Connect your feed by entering a URL or uploading a file, then choose the key you want to use.
        </div>
        <navigation-tabs size="small" class="importExportModal__navigation">
            <navigation-tabs-item :is-active="activeTab === 'external'" @click="switchTab('external')">
                URL Feed (External)
            </navigation-tabs-item>
            <navigation-tabs-item :is-active="activeTab === 'local'" @click="switchTab('local')">
                Upload File (Local)
            </navigation-tabs-item>
            <navigation-tabs-item :is-active="activeTab === 'generated'" @click="switchTab('generated')">
                Load from Production Studio
            </navigation-tabs-item>
        </navigation-tabs>
        <template v-if="activeTab === 'external' && !showPrimaryKeySelector">
            <hox-alert v-if="!showPrimaryKeySelector" type="info" class="importExportModal__howto">
                <template #title>
                    To add the feed for preview:
                    <span>
                        Enter the feed URL into the box below, select the unique key, and preview the feed values in the
                        editor.
                    </span>
                </template>
            </hox-alert>

            <div class="importExportModal__upload-controls">
                <label for="externalFeedUrl" class="importExportModal__upload-controls-label">
                    Enter your Feed URL:
                </label>
                <Input v-model="externalFeedUrl" placeholder="Enter your Feed URL"></Input>
                <span>
                    <Icon type="md-information-circle" />
                    We will store this file, making it easy to pull in new feeds if there are updates.
                </span>
                <Button type="primary" :disabled="!isValidUrl" @click="getExternalFeed">
                    <template>Continue</template>
                </Button>
            </div>
        </template>
        <template v-if="activeTab === 'local'">
            <hox-alert v-if="!showPrimaryKeySelector" type="info" class="importExportModal__howto">
                <template #title>To add the feed for preview:</template>
                <template #content>
                    Upload a local XML/JSON feed file, choose the unique key, and preview the feed values in the editor.
                </template>
            </hox-alert>

            <div v-if="showLocalUploadFirstStep" class="importExportModal__upload-controls">
                <feed-upload-button
                    class="batch-upload-wrapper"
                    @error="onFeedLoadFail"
                    @click.native.stop
                    @resetUploadedFile="onResetUploadedFile"
                    @xmlLoad="onXmlFileLoad"
                    @jsonLoad="onJsonFileLoad"
                >
                    <Button icon="ios-document-outline">
                        <span class="batch-upload--link">Choose a file</span>
                        to upload or drag it here
                    </Button>
                </feed-upload-button>
            </div>
        </template>
        <template v-if="activeTab === 'generated'">
            <RadioGroup v-model="productionStudioSource" class="importExportModal__external-generated">
                <Radio label="copywrite" value="copywrite">Copywrite</Radio>
                <Radio label="create" value="create">Create</Radio>
            </RadioGroup>
            <Button v-if="!showPrimaryKeySelector" type="primary" @click="onLoadGeneratedJson">
                Load generated JSON
            </Button>
        </template>

        <div class="importExportModal__upload-results">
            <hox-alert v-if="hasValuesError" type="danger" class="importExportModal__upload-result">
                <template #content>
                    <p>{{ valuesErrorMessage }}</p>
                    <ul v-if="valuesErrorDetails" class="importExportModal__upload-results-error-list">
                        <li v-for="detail in valuesErrorDetails" :key="detail.message">{{ detail.message }}</li>
                    </ul>
                </template>
            </hox-alert>

            <hox-alert v-if="isProcessingFeed" type="info" class="importExportModal__upload-result">
                <template #title>File loaded. Processing...</template>
                <template #content>
                    <!-- prettier-ignore -->
                    <p>Using "<strong>{{ primaryKey }}</strong>" as a primary key.</p>
                </template>
            </hox-alert>

            <hox-alert
                v-if="valuesUploadCompleted && !hasValuesError"
                type="success"
                class="importExportModal__upload-result"
            >
                <template #title>Feed upload successful</template>
                <template #content>
                    <p>Content has been imported. Please preview the changes.</p>
                </template>
            </hox-alert>
            <Button
                v-if="showLocalUploadContinueButton && showLocalUploadFirstStep"
                type="primary"
                @click="savePrimaryKey"
            >
                <template>Continue</template>
            </Button>
            <feed-primary-key-selector
                v-if="showPrimaryKeySelector"
                v-model="primaryKey"
                :candidates="primaryKeyCandidates"
                @select="onPrimaryKeySelected"
            />
        </div>
    </hox-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { CoverType, ResizeType } from "shared-utils/imageBoss";
import { parseStringPromise } from "xml2js";
import HoxModal from "@/components/Modal/Modal/Modal";
import { camelToUpperCase, objectIsEmpty } from "@/utils";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";
import { campaignDefaultLanguage, CampaignGetters } from "@/store/modules/campaign";
import ImportExport from "@/services/ImportExport";
import { OverwriteScope } from "@/enums/overwrites";
import FeedPrimaryKeySelector from "@/components/Campaign/FeedPrimaryKeySelector";
import ImportFeedFromUrl from "@/apollo/mutations/ImportFeedFromUrl.gql";
import FeedUploadButton from "@/components/Campaign/FeedUploadButton";
import mimetype from "@/enums/mimetype";

const feedsPrimaryField = "Creative_set";

const getXmlValue = field => {
    if (Array.isArray(field)) {
        return field[0];
    }

    if (objectIsEmpty(field)) {
        return null;
    }

    return field;
};

const getProducts = data => {
    if (data.Products) {
        return data.Products.Product;
    }

    return data.products.product;
};

export default {
    name: "FeedImportModal",
    components: {
        FeedUploadButton,
        FeedPrimaryKeySelector,
        HoxModal,
        NavigationTabs,
        NavigationTabsItem
    },
    props: {
        createNewGroupValues: {
            type: Function
        },

        groupName: {
            type: String
        },

        updatedFeedUrl: {
            type: String
        },

        updatedPrimaryKey: {
            type: String
        }
    },
    data() {
        return {
            activeTab: "external",
            allPrimaryKeyCandidates: [],
            externalFeedUrl: null,
            externalJsonSource: {
                copywrite: "https://d1waadlc35ag4q.cloudfront.net/copywrite_",
                create: "https://d1waadlc35ag4q.cloudfront.net/create_"
            },
            feedProducts: [],
            hasTranslationsError: false,
            hasValuesError: false,
            isExternalFeedLoading: true,
            isProcessingFeed: false,
            showLocalUploadFirstStep: true,
            showContinueAfterProcessedFeed: false,
            continueAfterProcessedFeed: false,
            primaryKey: null,
            primaryKeyCandidates: [],
            productionStudioSource: "copywrite",
            translationsErrorMessage: "",
            valuesErrorDetails: [],
            valuesErrorMessage: "",
            valuesUploadCompleted: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        internalCampaignId() {
            return this.$store.state.campaign.id;
        },

        campaignLanguages() {
            return this.$store.state.campaign.languages;
        },

        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        groupValueNamesByGroup() {
            return this.$store.getters[CampaignGetters.groupValueNamesByGroup];
        },

        headerTabName() {
            return camelToUpperCase(this.activeTab);
        },

        editableGroupValuesNames() {
            return this.groupValueNamesByGroup[this.groupName].editableGroupValuesNames.map(groupValueName =>
                this.sanitizeGroupValueName(groupValueName)
            );
        },

        groupEditables() {
            const { editables: groupEditableIds } = this.groupValueNamesByGroup[this.groupName];

            return groupEditableIds.map(editableId => this.editables[editableId]);
        },

        showLocalUploadContinueButton() {
            return !this.valuesUploadCompleted && !this.hasValuesError && this.primaryKeyCandidates.length;
        },

        showPrimaryKeySelector() {
            return this.showContinueAfterProcessedFeed && this.showLocalUploadContinueButton;
        },

        modalClass() {
            return this.updatedFeedUrl !== "" ? "importExportModal--update" : "importExportModal--upload";
        },

        isValidUrl() {
            try {
                const parsedUrl = new URL(this.externalFeedUrl);
                if (!["http:", "https:", "ftp:"].includes(parsedUrl.protocol)) {
                    return false;
                }
                return /\.(json|xml)$/i.test(parsedUrl.pathname);
            } catch (e) {
                return false;
            }
        }
    },

    watch: {
        externalFeedUrl(newVal) {
            this.$emit("updateExternalFeedUrl", newVal);
        },
        primaryKey(newVal) {
            this.$emit("updatePrimaryKey", newVal);
        }
    },

    created() {
        if (this.updatedFeedUrl && this.updatedPrimaryKey) {
            this.externalFeedUrl = this.updatedFeedUrl;
            this.primaryKey = this.updatedPrimaryKey;
            this.updateFeed();
        }
        this.importExportService = new ImportExport(this.$apollo, this.$store);
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async getExternalFeed() {
            await this.fetchFile();
            this.showContinueAfterProcessedFeed = true;
            this.showLocalUploadFirstStep = false;
        },

        async fetchFile() {
            this.resetState();
            this.isExternalFeedLoading = true;

            try {
                const { data } = await this.$apollo.mutate({
                    mutation: ImportFeedFromUrl,
                    variables: {
                        campaignId: this.campaignId,
                        url: this.externalFeedUrl
                    }
                });

                const response = await fetch(data.importFeedFromUrl);
                if (!response.ok) {
                    this.onFeedLoadFail("There was an error when loading the external url");
                    return;
                }
                const responseBlob = await response.blob();
                const stringContent = await responseBlob.text();

                if (responseBlob.type === mimetype.JSON) {
                    await this.processJson(JSON.parse(stringContent));
                    this.isExternalFeedLoading = false;
                    return;
                }

                if (responseBlob.type === mimetype.XMLA || responseBlob.type === mimetype.XML) {
                    const xml = await parseStringPromise(stringContent);
                    await this.processXml(xml);
                    this.isExternalFeedLoading = false;
                    return;
                }

                this.onFeedLoadFail("Invalid input file format. Please use .xml or .json feed file");
            } catch (err) {
                console.error(err);
                this.onFeedLoadFail("There was an error when loading the external url");
            }
        },

        onFeedLoadFail(msg = "There was an error when loading the feed") {
            this.valuesErrorMessage = msg;
            this.isExternalFeedLoading = false;
            this.hasValuesError = true;
        },

        async onLoadGeneratedJson() {
            this.externalFeedUrl = `${this.externalJsonSource[this.productionStudioSource]}${this.internalCampaignId}.json`;
            await this.getExternalFeed();
        },

        async onXmlFileLoad(data) {
            await this.processXml(data);
        },

        async onJsonFileLoad(data) {
            await this.processJson(data);
        },

        onPrimaryKeySelected() {
            this.processFeed(this.feedProducts, this.primaryKey);
            this.primaryKeyCandidates = [];
        },

        async processXml(data) {
            this.resetState();

            if (!data.Products.Product.length) {
                this.onFeedLoadFail(
                    "The file is either empty or is incorrectly formatted. (No Product nodes under Product root)"
                );
                return;
            }

            await this.$nextTick();
            // We will try to find a field that can be used as an unique field
            this.processFileData(getProducts(data));
        },

        async processJson(data) {
            this.resetState();

            if (!Array.isArray(data) || !data.length) {
                this.onFeedLoadFail("The file is either empty or is incorrectly formatted.");
                return;
            }

            await this.$nextTick();

            // Filter null or undefined values from objects
            const filteredData = data.map(obj =>
                Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null && value !== undefined))
            );
            this.processFileData(filteredData);
        },

        processFileData(data) {
            // We will try to find a field that can be used as an unique field
            this.feedProducts = data;
            const sampleFields = Object.keys(this.feedProducts[0]);
            const uniqueKeyAccumulator = sampleFields.reduce((acc, field) => {
                acc[field] = new Set();
                return acc;
            }, {});

            const uniqueValuesByKey = this.feedProducts.reduce((acc, product) => {
                sampleFields.forEach(field => {
                    acc[field].add(getXmlValue(product[field]));
                });

                return acc;
            }, uniqueKeyAccumulator);

            const primaryKeyCandidates = sampleFields.filter(
                field => uniqueValuesByKey[field].size === this.feedProducts.length
            );

            this.allPrimaryKeyCandidates = sampleFields;

            if (!primaryKeyCandidates.length) {
                this.primaryKey = sampleFields[0];
                this.primaryKeyCandidates = sampleFields;
                return;
            }

            this.primaryKey = primaryKeyCandidates[0];
            if (this.updatedPrimaryKey) {
                this.primaryKey = this.updatedPrimaryKey;
            }
            if (primaryKeyCandidates.length === 1) {
                this.processFeed(this.feedProducts, this.primaryKey);
                return;
            }

            // we have more then one candidate
            this.primaryKeyCandidates = primaryKeyCandidates;
        },

        async processFeed(products, primaryKey = feedsPrimaryField) {
            this.isProcessingFeed = true;
            const mappedSample = this.mapProduct(products[0], primaryKey);
            if (mappedSample.groupValueName === null) {
                this.onFeedLoadFail(`Primary key (${primaryKey}) has not been found on the product`);
                this.isProcessingFeed = false;
                return;
            }

            const hasEditableMatchesInGroup = !!Object.keys(mappedSample.editables).length;

            if (!hasEditableMatchesInGroup) {
                this.isProcessingFeed = false;
                this.onFeedLoadFail("None of the fields in the feed matches editables in the selected group");
                this.onResetUploadedFile();
                return;
            }

            const operations = products.reduce(
                (acc, product) => {
                    const groupValueName = Array.isArray(product[primaryKey])
                        ? product[primaryKey][0]
                        : product[primaryKey];
                    if (this.editableGroupValuesNames.includes(this.sanitizeGroupValueName(groupValueName))) {
                        acc.toUpdate.add(this.mapProduct(product, primaryKey));
                    } else {
                        acc.toAdd.add(this.mapProduct(product, primaryKey));
                    }

                    return acc;
                },
                { toAdd: new Set(), toUpdate: new Set() }
            );
            if (operations.toAdd.size || operations.toUpdate.size) {
                try {
                    await this.processBatch({
                        toAdd: [...operations.toAdd],
                        toUpdate: [...operations.toUpdate]
                    });
                } catch (err) {
                    this.onFeedLoadFail("Failed to process the uploaded feed.");
                    this.valuesErrorDetails = [err];
                }
            }
            this.isProcessingFeed = false;
        },

        sanitizeGroupValueName(name) {
            return name.trim();
        },

        sanitizePrimaryKey(key) {
            return key.toLowerCase();
        },

        resetState() {
            this.valuesUploadCompleted = false;
            this.showContinueAfterProcessedFeed = false;
            this.showLocalUploadFirstStep = true;
            this.hasValuesError = false;
            this.valuesErrorDetails = [];
            this.feedProducts = [];
            this.primaryKey = null;
            this.primaryKeyCandidates = [];
        },

        mapProduct(product, primaryKey = feedsPrimaryField) {
            const lowerCasedProduct = Object.keys(product).reduce((acc, key) => {
                acc[this.sanitizePrimaryKey(key)] = product[key];

                return acc;
            }, {});
            const nameMatches = this.groupEditables.reduce((acc, editable) => {
                if (lowerCasedProduct[editable.name.toLowerCase()]) {
                    acc[editable.name] = getXmlValue(lowerCasedProduct[editable.name.toLowerCase()]);
                }

                return acc;
            }, {});

            const uniqueKeyValue = lowerCasedProduct[this.sanitizePrimaryKey(primaryKey)];
            const lowercaseGroupValueName = uniqueKeyValue || null;

            const groupValueName = Array.isArray(lowercaseGroupValueName)
                ? lowercaseGroupValueName[0]
                : lowercaseGroupValueName;

            return {
                groupValueName,
                editables: nameMatches
            };
        },

        async processBatch({ toAdd, toUpdate }) {
            if (toAdd.length) {
                const uniqueGroupValuesToCreate = new Set(toAdd.map(({ groupValueName }) => groupValueName).flat());
                await this.createNewGroupValues(this.groupName, [...uniqueGroupValuesToCreate]);
            }
            const { editableGroupValuesNamesMap, editables: editableIds } =
                this.$store.getters[CampaignGetters.groupValueNamesByGroup][this.groupName];

            const editableByName = editableIds.reduce((acc, editableId) => {
                acc[this.editables[editableId].name] = this.editables[editableId];
                return acc;
            }, {});

            await Promise.all(
                [...toAdd, ...toUpdate]
                    .map(({ editables, groupValueName }) => {
                        const updateEditableNames = Object.keys(editables);

                        return updateEditableNames.map(editableName =>
                            this.setFeedImportedValue(
                                editableByName[editableName],
                                [editableGroupValuesNamesMap[this.sanitizeGroupValueName(groupValueName)]],
                                editables[editableName]
                            )
                        );
                    })
                    .flat()
            );

            this.valuesUploadCompleted = true;
            this.$emit("closeWithSuccess", this.groupName);
        },

        setFeedImportedValue(editable, groupValueIds, value) {
            return this.importExportService.applyEditableUpdate({
                language: campaignDefaultLanguage,
                value,
                editableGroupValueIds: groupValueIds,
                editable,
                scope: OverwriteScope.EditableGroup,
                resizeSettings: { cover: CoverType.Smart },
                resizeType: this.useAutoResize ? ResizeType.Auto : ResizeType.Off
            });
        },

        switchTab(tab) {
            this.resetState();
            this.activeTab = tab;
        },

        async updateFeed() {
            await this.fetchFile();
            await this.processFeed(this.feedProducts, this.primaryKey);
            this.primaryKeyCandidates = [];
            this.$emit("close");
        },

        savePrimaryKey() {
            this.showLocalUploadFirstStep = false;
            this.showContinueAfterProcessedFeed = true;
        },

        onResetUploadedFile() {
            this.showLocalUploadFirstStep = true;
            this.primaryKeyCandidates = [];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.importExportModal {
    &__upload-controls-label {
        margin-right: $spacing-small;
    }

    &__navigation {
        margin-bottom: $spacing;
        border-bottom: 2px solid #e7eaee;
        li {
            margin-bottom: -2px;
        }
    }

    &__howto {
        &-list {
            padding-left: $spacing;
        }
    }

    &__files {
        padding-left: $spacing;
        margin-bottom: $spacing;
    }

    &__upload-result {
        margin-top: $spacing;
    }

    &__upload-results-error-list {
        padding-left: $spacing;
        word-break: break-word;
    }

    &__upload-controls {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        gap: 10px;

        .labelled-switch {
            flex: 0;

            &__label {
                white-space: nowrap;
            }
        }
        .editable-group-batch-upload {
            width: 100%;
            .ivu-upload {
                display: block;
                height: 150px;
            }
            &__button {
                height: 100%;
            }
            &__description {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 10px 0;
                background: #f4f5f7;
                padding: 5px 10px;
                .ivu-icon {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
            .editable-group-batch-upload__button {
                button.ivu-btn {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    border: 1px dashed #333;
                    width: 100%;
                    height: 100%;
                    color: #333;
                    font-weight: normal;
                    span {
                        &.batch-upload--link {
                            color: #0014cc;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    &--update {
        opacity: 0;
        top: 100%;
    }
    &--upload {
        .hox-modal {
            width: 840px;
        }
        .alert {
            border-radius: 8px;
            background-color: #e7eaee;
            padding: 16px;
            &.importExportModal__upload-result {
                margin-top: 0;
                padding-top: 0;
                padding-left: 0;
                background: none;
                .alert__icon-container {
                    display: none;
                }
                h3 {
                    display: none;
                }
                label {
                    display: flex;
                    align-items: center;
                }
            }
            &.alert--type-danger {
                background-color: #fde7e7;
                border-radius: 0;
                padding: 5px 10px;
                margin-top: 10px;
                p {
                    margin: 0;
                }
            }
            &__content {
                span {
                    display: block;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            &__icon-container {
                padding-right: 10px;
            }
            .ivu-icon {
                font-size: 22px !important;
                color: #8b919a;
            }
        }
        .importExportModal__upload-controls {
            align-items: flex-start;
        }
        .hox-modal__body {
            padding-top: 0;
        }
        .hox-modal__header {
            margin-bottom: 10px;
        }
    }
    &__subtitle {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px;
    }
    &__external-generated {
        margin-bottom: 15px;
    }
}
</style>

// eslint-disable-next-line import/no-extraneous-dependencies
import { UserActions, UserActionsToAuthorizeByScope } from "shared-utils/enums/user";
import { Amplify } from "aws-amplify";
import {
    fetchAuthSession,
    signIn,
    signOut,
    signInWithRedirect,
    resetPassword,
    confirmResetPassword,
    confirmSignIn
} from "aws-amplify/auth";

import store from "@/store";
import { AuthGetters } from "@/store/modules/auth";
import { DefaultUser } from "@/enums/users";
import { getWppToken } from "@/services/wppAuth";

export const Actions = UserActions;

export async function logout() {
    // Returns true to confirm the user was logged out
    await signOut();
    return true;
}

export async function login(username, password) {
    return signIn({ username, password });
}

export async function providerLogin(provider) {
    return signInWithRedirect({ provider: { custom: provider } });
}

function getName(user, firstNameOnly) {
    if (firstNameOnly) {
        return user.given_name;
    }

    if (user.given_name && user.family_name) {
        return `${user.given_name} ${user.family_name}`;
    }

    if (user.email) {
        return user.email;
    }

    if (user["cognito:username"]) {
        return user["cognito:username"];
    }

    return null;
}

export async function userDisplayName(firstNameOnly = false) {
    // Get current oauth session and user
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return DefaultUser;
    }

    // Return default display name if user name not found
    return getName(tokens.idToken.payload, firstNameOnly) || DefaultUser;
}

export async function userEmail() {
    // Get current oauth session and user
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return "";
    }

    // Return default display name if user name not found
    return tokens.idToken.payload.email;
}

export async function getPayload() {
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return {};
    }

    return tokens.idToken.payload;
}

async function getAmplifyToken() {
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return null;
    }

    return tokens.idToken.toString();
}

export async function getIdToken() {
    if (store.getters[AuthGetters.isWppLogin]) {
        return getWppToken();
    }

    return getAmplifyToken();
}

export async function getIsAuthorized() {
    const response = await fetch(`${window.location.origin}/oauth-config`);

    if (response.status !== 200) {
        const error = await response.json();
        throw new Error(error.message || "Error retrieving Auth Config");
    }

    const authConfig = await response.json();

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: authConfig.userPoolId,
                userPoolClientId: authConfig.userPoolWebClientId,
                loginWith: {
                    oauth: {
                        domain: authConfig.ssoURL.replace(/^https?:\/\//, ""),
                        redirectSignIn: [`https://${document.domain}/`],
                        redirectSignOut: [`https://${document.domain}/`],
                        scopes: ["openid"],
                        responseType: "code"
                    }
                }
            }
        }
    });

    return !!(await getAmplifyToken());
}

export async function forgotPassword(username) {
    return resetPassword({ username });
}

export async function forgotPasswordSubmit(username, code, password) {
    return confirmResetPassword({ username, confirmationCode: code, newPassword: password });
}

export async function completeNewPassword(password) {
    return confirmSignIn({ challengeResponse: password });
}

export async function hasScope(scope, exact = true) {
    return store.getters[AuthGetters.hasScope](scope, exact);
}

/**
 *
 * @param {keyof typeof UserActions} action
 * @param {{ clientId: string; campaignId?: string} | null} [scope]
 * @param {boolean} [exact]
 * @returns {Promise<boolean>}
 */
export async function userCan(action, scope = null, exact = true) {
    const isScopeRequired = UserActionsToAuthorizeByScope.includes(action);

    if (isScopeRequired && !scope) {
        throw new Error(`AuthService: Scope required for action: ${action}`);
    }

    return isScopeRequired
        ? store.getters[AuthGetters.userCan](action) && scope && hasScope(scope, exact)
        : store.getters[AuthGetters.userCan](action);
}

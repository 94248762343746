<template>
    <div class="editable-group" :class="{ 'editable-group--editing': isEditingGroup }">
        <collapsible-widget
            :is-opened="false"
            class="editable-group__collapsible-widget"
            @input="onCollapsibleWidgetInput"
        >
            <template slot="name">
                <span class="capitalize-text">{{ groupLabel }}</span>
            </template>
            <template v-if="hasImportedFeedValues" slot="controls">
                <Poptip
                    v-model="isPoptipActive"
                    placement="bottom-end"
                    class="editable-group__feed-more"
                    trigger="hover"
                >
                    <div data-testid="editable-group__more-menu">
                        <div class="editable-group__more-icon">
                            <Icon type="ios-more" @click.stop="togglePoptip"></Icon>
                        </div>
                    </div>
                    <template #content>
                        <slot name="poptipContent">
                            <div
                                class="editable-group__action"
                                data-testid="editable-group__refresh"
                                @click.stop="refreshFeed"
                            >
                                <Icon type="md-refresh" />
                                <span class="editable-group-actions__refresh">Refresh</span>
                            </div>
                            <div
                                class="editable-group__action"
                                data-testid="editable-group__import"
                                @click.stop="importFeed"
                            >
                                <Icon type="md-cloud-upload" />
                                <span class="editable-group-actions__refresh">Replace</span>
                            </div>
                            <div
                                class="editable-group__action"
                                data-testid="editable-group__delete"
                                @click.stop="toggleDeleteFeedModal"
                            >
                                <Icon type="ios-trash" />
                                <span class="editable-group-actions__delete">Delete</span>
                            </div>
                        </slot>
                    </template>
                </Poptip>
            </template>
            <template v-else slot="controls">
                <Icon class="editable-group__feed-upload" type="md-cloud-upload" @click.stop="importFeed" />
            </template>
            <div class="editable-group__body">
                <template v-if="editableGroupValuesForGroup.length">
                    <RadioGroup class="editable-group__values" :vertical="true" :value="getGroupValue()">
                        <div
                            v-for="editableGroupValueId in editableGroupValuesForGroup"
                            :key="editableGroupValueId"
                            class="editable-group__value-wrapper"
                            data-testid="editable-group__value-wrapper"
                        >
                            <div v-if="isEditing !== editableGroupValueId">
                                <Radio
                                    :label="getEditableGroupValueById(editableGroupValueId)"
                                    @click.native.stop.prevent="onGroupValueChange(editableGroupValueId)"
                                ></Radio>
                                <div v-if="canManageGroupValues" class="editable-group__value-actions">
                                    <Icon
                                        type="md-create"
                                        data-testid="editable-group__value-actions--edit"
                                        @click="
                                            showEditingForm(
                                                editableGroupValueId,
                                                getEditableGroupValueById(editableGroupValueId)
                                            )
                                        "
                                    />
                                    <Icon
                                        type="md-copy"
                                        data-testid="editable-group__value-actions--duplicate"
                                        @click="duplicateGroupValue(editableGroupValueId)"
                                    />
                                    <Icon
                                        type="md-trash"
                                        data-testid="editable-group__value-actions--delete"
                                        @click="
                                            onGroupValueRemove(
                                                editableGroupValueId,
                                                getEditableGroupValueById(editableGroupValueId)
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div v-else class="editable-group__add">
                                <Input
                                    :id="`editingInput-${editableGroupValueId}`"
                                    v-model="editingValue"
                                    @on-enter="
                                        editValue(editableGroupValueId, getEditableGroupValueById(editableGroupValueId))
                                    "
                                ></Input>
                                <Icon
                                    type="md-checkmark"
                                    @click="
                                        editValue(editableGroupValueId, getEditableGroupValueById(editableGroupValueId))
                                    "
                                />
                                <Icon type="md-close" @click="cancelEdit(editableGroupValueId)" />
                            </div>
                        </div>
                    </RadioGroup>
                </template>
                <div v-if="canManageGroupValues">
                    <template v-if="!isAdding">
                        <div class="editable-group__controls" @click.stop="showForm">
                            <Icon type="md-add" class="show-form" />
                            <span class="editable-group__controls--tip">Add new</span>
                        </div>
                    </template>
                    <div v-else class="editable-group__add">
                        <Input ref="addingInput" v-model="newValue" @on-enter="addValue"></Input>
                        <Icon type="md-checkmark" @click="addValue" />
                        <Icon type="md-close" @click="cancelAdd" />
                    </div>
                </div>
            </div>
        </collapsible-widget>
        <alert-modal
            v-model="removeFeedModal"
            title="Are you sure?"
            content="If you decide to delete this feed, you'll need to add or upload a new one."
            :warning="false"
            class="remove-feed__modal"
        >
            <template #footer>
                <Button
                    class="campaign-edit__confirm-unsaved-changes-save-button"
                    type="error"
                    @click="deleteExternalFeed"
                >
                    Delete
                </Button>
                <Button @click.stop="toggleDeleteFeedModal">Cancel</Button>
            </template>
        </alert-modal>

        <div v-if="removeGroupValueModal">
            <alert-modal
                v-if="editableGroupValuesForGroup.length === 1"
                v-model="removeGroupValueModal"
                header="Cannot remove the group value"
                content="Group has to contain at least one group value"
                :warning="true"
                :show-cancel="false"
                :on-ok="clearRemoveGroupValueCallback"
            />
            <alert-modal
                v-else
                v-model="removeGroupValueModal"
                :header="'Remove group value'"
                :title="'Are you sure?'"
                :content="'Removing the group value will delete all content edits for that group value.'"
                :ok-text="'Remove'"
                :on-cancel="clearRemoveGroupValueCallback"
                :on-ok="removeGroupValueCallback"
            />
        </div>
    </div>
</template>
<script>
import AlertModal from "@/components/Modal/Alert";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { EditableGroupEvent } from "@/enums/editables";
import { formatGroupName } from "@/utils";

export default {
    name: "EditableGroup",
    components: {
        CollapsibleWidget,
        AlertModal
    },

    props: {
        groupName: {
            type: String,
            required: true
        },
        sandboxMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isAdding: false,
            isEditing: false,
            removeGroupValueModal: false,
            removeGroupValueCallback: null,
            newValue: "",
            editingValue: "",
            isPoptipActive: false,
            removeFeedModal: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        canManageGroupValues() {
            return (
                (this.sandboxMode && this.$auth.userCan(this.$auth.Actions.CanUseSandbox)) ||
                this.$auth.userCan(this.$auth.Actions.CanManageGroupValue, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            );
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        editableGroupIds() {
            return this.$store.state.campaign.normalized.editableGroupIds;
        },

        editableGroups() {
            return this.$store.state.campaign.normalized.editableGroups;
        },

        editableGroupValues() {
            return this.$store.state.campaign.editableGroupValues;
        },

        editableGroupValuesForGroup() {
            return this.editableGroups[this.groupName].editableGroupValues;
        },

        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        groupLabel() {
            return formatGroupName(this.groupName);
        },

        isEditingGroup() {
            return this.groupName === this.$store.state.editor.editingGroupName;
        },

        selectedGroupValues() {
            return this.$store.state.editor.selectedGroupValues;
        },

        hasImportedFeedValues() {
            const savedGroup = this.$store.state.campaign.groupFeedMappings?.find(
                mapping => mapping.group === this.groupName
            );
            return savedGroup && savedGroup.feedUrl;
        }
    },

    methods: {
        addValue() {
            const value = this.newValue.trim();

            if (value === "") {
                this.cancelAdd();
                return;
            }

            const usedValues = this.editableGroups[this.groupName].editableGroupValues.map(
                editableGroupValueId => this.editableGroupValues[editableGroupValueId].value
            );

            if (usedValues.includes(value)) {
                this.$snackbar.warning("The value needs to be unique");
                return;
            }

            this.$emit(EditableGroupEvent.Add, {
                groupName: this.groupName,
                value
            });

            this.isAdding = false;
        },

        duplicateGroupValue(groupValueId) {
            this.$emit(EditableGroupEvent.Duplicate, {
                groupName: this.groupName,
                groupValueId
            });
        },

        async showEditingForm(groupValueId, groupValue) {
            this.editingValue = groupValue;
            this.isEditing = groupValueId;

            await this.$nextTick();

            const input = document.querySelector(`#editingInput-${groupValueId} input`);

            if (input) {
                input.focus();
            }
        },

        async showForm() {
            this.newValue = "";
            this.isAdding = true;

            await this.$nextTick();

            if (this.$refs.addingInput) {
                this.$refs.addingInput.focus();
            }
        },

        cancelAdd() {
            this.isAdding = false;
        },

        cancelEdit() {
            this.isEditing = false;
        },

        clearRemoveGroupValueCallback() {
            this.removeGroupValueCallback = null;
            this.removeGroupValueModal = false;
        },

        editValue(groupValueId, groupValue) {
            if (this.isEditing !== groupValueId) {
                return;
            }

            const value = this.editingValue.trim();

            if (value === "" || value === groupValue) {
                this.cancelEdit();
                return;
            }

            const usedValues = this.editableGroups[this.groupName].editableGroupValues.map(
                editableGroupValueId => this.editableGroupValues[editableGroupValueId].value
            );

            if (usedValues.includes(value)) {
                this.$snackbar.warning("The value needs to be unique");
                return;
            }

            this.$emit(EditableGroupEvent.Edit, {
                groupName: this.groupName,
                groupValue,
                editableGroupValues: [
                    {
                        editableGroupValueId: groupValueId,
                        value
                    }
                ]
            });

            this.cancelEdit();
        },

        getEditableGroupValueById(editableGroupValueId) {
            return this.editableGroupValues[editableGroupValueId].value;
        },

        getGroupValue() {
            if (this.groupName in this.selectedGroupValues) {
                const editableGroupGroupId = this.selectedGroupValues[this.groupName].groupValueId;

                if (editableGroupGroupId && this.editableGroupValues[editableGroupGroupId]) {
                    return this.editableGroupValues[editableGroupGroupId].value;
                }
            }

            return null;
        },

        importFeed() {
            this.$emit("importFeed", this.groupName);
        },

        refreshFeed() {
            this.$emit("refreshFeed", this.groupName);
        },

        onGroupValueChange(editableGroupValueId) {
            const payload = {
                groupValueId: editableGroupValueId,
                groupName: this.groupName
            };

            this.$emit(EditableGroupEvent.Update, payload);
        },

        onGroupValueRemove(groupValueId, groupValue) {
            this.removeGroupValueModal = true;
            this.removeGroupValueCallback = () => {
                // If group being remove is selected switch to default
                if (this.getGroupValue() === groupValue) {
                    this.onGroupValueChange(this.defaultGroupValue);
                }
                // Remove group
                this.$emit(EditableGroupEvent.Remove, {
                    groupName: this.groupName,
                    groupValue,
                    editableGroupValueIds: [groupValueId]
                });
                // Clear callback
                this.clearRemoveGroupValueCallback();
            };
        },

        onCollapsibleWidgetInput(isOpened) {
            if (!isOpened) {
                this.isPoptipActive = false;
            }
        },

        toggleDeleteFeedModal() {
            this.removeFeedModal = !this.removeFeedModal;
        },

        togglePoptip() {
            this.isPoptipActive = !this.isPoptipActive;
        },

        deleteExternalFeed() {
            this.toggleDeleteFeedModal();
            this.$emit("deleteFeed", this.groupName);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.editable-group {
    &:hover .editable-group__feed-upload {
        display: flex;
        color: $white;
    }

    &:hover .editable-group__feed-more,
    &:hover .editable-group__refresh {
        display: flex;
        color: $darktheme100;
    }
    &__action {
        display: flex;
        gap: 5px;
        margin: 5px 0;
        color: #121619;
    }

    &__feed-more,
    &__feed-upload,
    &__refresh {
        display: none;
    }

    &__collapsible-widget .collapsible-widget__panel-controls {
        flex: 0 1;
        display: flex;
        justify-content: center;
        align-content: space-between;
        height: 100%;
    }

    &--editing {
        .ivu-collapse > .ivu-collapse-item {
            &.ivu-collapse-item-active {
                .ivu-collapse-header {
                    background: $cmp-dark-active;
                }
            }
        }
    }

    &__body {
        padding: 0;

        .ivu-radio-wrapper,
        .editable-group__controls {
            cursor: pointer;
            margin: 0;
            line-height: 30px;
            padding: 0 20px;

            &.ivu-radio-wrapper-checked,
            &:hover {
                background: $cmp-dark-tertiary-bg-color;

                .show-form {
                    color: $cmp-dark-active;
                }

                .ivu-radio .ivu-radio-inner {
                    border-color: $cmp-dark-active;
                }
            }
        }
    }

    &__add {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-between;
        background: $cmp-dark-tertiary-bg-color;
        border-left: 2px solid $cmp-dark-active;

        .ivu-input-wrapper {
            margin-right: 10px;

            .ivu-input {
                border: 1px solid $darktheme20;
                height: 20px;
                margin: 5px;
            }
        }

        i {
            margin: 0;
            line-height: 30px;
            cursor: pointer;

            &:last-of-type {
                margin-right: 5px;
            }

            &:hover {
                color: $darktheme20;
            }
        }
    }

    &__values {
        width: 100%;
        padding: 0 !important;
    }

    &__controls {
        &--tip {
            font-size: 14px;
            font-style: italic;
            padding-left: 8px;
            color: $cmp-dark-disabled-color;
        }

        &:hover {
            .editable-group__controls--tip {
                color: $cmp-dark-font-color;
            }
        }
    }

    &__value-wrapper {
        position: relative;

        &:hover {
            .editable-group__value-actions {
                display: block;
            }
        }
    }

    &__value-actions {
        display: none;
        position: absolute;
        top: 3px;
        right: 6px;

        i {
            transition: opacity 0.3s;
            opacity: 0.25;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    .ivu-collapse-content-box {
        border-bottom: 1px solid $cmp-dark-border-color;
    }

    .ivu-collapse-header {
        .collapsible-widget__panel-controls {
            margin: 0 15px;

            &:hover {
                color: $cmp-dark-active;
            }
        }
    }

    .show-form {
        border-radius: 50%;
        border: 2px solid;
        font-size: 10px;
        color: $blue;
    }
}
.remove-feed__modal {
    .ivu-nodal-header {
        padding: 0;
    }
    .ivu-modal-body {
        padding-top: 0;
        padding-bottom: 0;
    }
    .ivu-modal-footer {
        .ivu-btn {
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .alert-modal {
        &__icon-wrapper {
            display: none;
        }
        &__content-wrapper {
            padding-left: 0;
        }
        &__title {
            font-size: 18px;
            line-height: 28px;
            color: #4d5358;
        }
        &__content {
            padding-right: 50px;
        }
    }
}
</style>

<template>
    <div class="creative-insights-header" :class="classes">
        <section-nav />
        <div class="creative-insights-header__advanced-search creative-insights-header__item">
            <advanced-search-input />
        </div>
        <div class="creative-insights-header__date-picker creative-insights-header__item">
            <tree-select-breakdown-filter @selectedBreakdowns="selectedDynamicBreakdowns = $event" />
        </div>
        <div class="creative-insights-header__date-picker creative-insights-header__item">
            <DatePicker
                class="creative-insights-header__date-picker-control"
                type="daterange"
                placement="bottom-end"
                placeholder="Report Date Range"
                :value="startDate"
                :options="restrictRange"
                @on-change="onChange"
            ></DatePicker>
        </div>
        <creative-insights-header-settings-button
            class="creative-insights-header__item creative-insights-header__settings"
        />
    </div>
</template>
<script>
import AdvancedSearchInput from "@/components/CreativeIntelligence/AdvancedSearchInput";
import CreativeInsightsHeaderSettingsButton from "@/components/CreativeIntelligence/CreativeInsightsHeaderSettingsButton";
import TreeSelectBreakdownFilter from "@/views/CreativeInteligence/TreeSelectBreakdownFilter";
import SectionNav from "@/components/SectionNav";
import { CreativeInsightsAction } from "@/store/modules/creativeInsights";

export default {
    name: "CreativeInsightsHeader",
    components: { TreeSelectBreakdownFilter, CreativeInsightsHeaderSettingsButton, AdvancedSearchInput, SectionNav },

    data() {
        return {
            restrictRange: {
                disabledDate: this.disabledDate
            }
        };
    },

    computed: {
        campaignMetadata() {
            return this.$store.state.creativeInsights.metadata;
        },

        classes() {
            if (!this.$store.state.ui.isSidebarOpen) {
                return "creative-insights-header--sidebar-closed";
            }

            return "";
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        clientLink() {
            return {
                name: "CampaignsDashboard",
                params: { clientId: this.clientId }
            };
        },

        clientLogo() {
            return this.$store.state.ui.currentClient && this.$store.state.ui.currentClient.logoUrl;
        },

        selectedDynamicBreakdowns: {
            get() {
                return this.$store.state.creativeInsights.selectedDynamicBreakdowns;
            },

            set(val) {
                this.$store.dispatch(CreativeInsightsAction.SetSelectedDynamicBreakdowns, val);
            }
        },

        selectedStartDate: {
            get() {
                return this.selectedDynamicBreakdowns.startDate[0];
            },

            set(val) {
                this.$store.dispatch(CreativeInsightsAction.SetSelectedDynamicBreakdowns, {
                    ...this.selectedDynamicBreakdowns,
                    "date.start": val ? [val] : []
                });
            }
        },

        selectedEndDate: {
            get() {
                return this.selectedDynamicBreakdowns.endDate[0];
            },

            set(val) {
                this.$store.dispatch(CreativeInsightsAction.SetSelectedDynamicBreakdowns, {
                    ...this.selectedDynamicBreakdowns,
                    "date.end": val ? [val] : []
                });
            }
        },

        startDate() {
            return [this.campaignMetadata.minDate, this.campaignMetadata.maxDate];
        }
    },
    methods: {
        disabledDate(date) {
            return (
                date &&
                (date.valueOf() > Date.parse(this.$store.state.creativeInsights.metadata.maxDate) ||
                    date.valueOf() < Date.parse(this.$store.state.creativeInsights.metadata.minDate))
            );
        },

        onChange([startDate, endDate]) {
            this.selectedStartDate = startDate;
            this.selectedEndDate = endDate;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.creative-insights-header {
    height: 120px;
    width: calc(100% + 3px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $spacing-semi-large $spacing-large $spacing-semi-large $spacing-larger - 4;
    background-color: $white;
    margin-left: -3px;

    &.creative-insights-header--sidebar-closed {
        margin-left: -13px;
        padding: $spacing-semi-large $spacing-large $spacing-semi-large $spacing-large + 26;
        width: calc(100% + 13px);
    }

    &__item {
        flex: 0 0;
        padding: 7px;

        &.creative-insights-header__date-picker {
            margin-top: -10px;
        }
    }

    &__advanced-search {
        flex: 1 1;
        margin: 0 28px 0 0;
        padding-left: 0px;
    }

    &__date-picker-control {
        width: 190px;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-right: 35px;

        &-image {
            max-width: 100px;
            max-height: 50px;
            image-rendering: -webkit-optimize-contrast;
        }

        &-name {
            font-size: 12px;
        }
    }
}
</style>
